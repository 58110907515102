import React, { useRef,Component } from "react";
import ReactToPrint from "react-to-print";
import { Row, Col,Button } from "reactstrap";

class ComponentToPrint extends Component {
// class ComponentToPrint extends React.PureComponent<IReactPrintProps, IReactPrintState> {
  constructor(props) {
    super(props);
    this.state = {
      body: props.body
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.state.body}
      </React.Fragment>
    );
  }
}

/* tslint:disable */
const REACT_PRINT_COMPONENT = ({ body}) => {
  const componentRef = useRef();
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} className="text-right mt-5">
          <ReactToPrint trigger={() => <Button color="success">Imprimir</Button>} content={() => componentRef.current} removeAfterPrint={true} />
        </Col>
        <Col xs={12}>
          <ComponentToPrint ref={componentRef} body={body} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default REACT_PRINT_COMPONENT;
