import React, { Component } from "react";
import Label from "reactstrap/lib/Label";
import { urlHttpPostAvatar } from "./../constant/Services";
import { PROFILE, ChangeAvatar,ModalExampleA } from "./../constant/Modal";
import { urlFiles } from "./../constant/Services";
import Datatable from "./../constant/Datatable/Index";
import REACT_PRINT_COMPONENT from "../constant/React_print";
import StoreContext from "./../../Store/Context";
import moment from "moment";
import {
  COLUMNS_ORDERS_ACCOUNT,
  COLUMNS_TRACKS_ACCOUNT,
  COLUMNS_TRACKS_ACCOUNT_FILTER,
  COLUMNS_TRACKS_ACCOUNT_MOBILE,
  COLUMNS_TRACKS_ACCOUNT_FILTER_MOBILE,
  COLUMNS_ORDERS_ACCOUNT_MOBILE,
} from "./../constant/Datatable/DatatableColumns";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import {
  LOGO_ICON,
  CHECK,
  INFO,
  BGENERAL,
  YAPPY,
  GLOBAL_BANK,
  BANCO_NACIONAL,
  PORT_TERMINAL,
} from "../constant/Index";
import classnames from "classnames";
import "./../../assets/css/main.css";
import {
  getUserAccount,
  getTrackingAccount,
  getSettingAccount,
  putProfile,
  getOrderAccount,
} from "./../constant/Services";

class Profile extends Component {
  static contextType = StoreContext;
  constructor() {
    super();
    this.state = {
      dataUser: {
        usr_id: "",
        usr_name: "",
        usr_code: "",
        usr_box_vol: "",
        usr_box_pr: "",
        usr_price: "",
        usr_handler: "",
        usr_birthday: "",
        usr_sex: "",
        usr_avatar: "",
        usr_address: "",
        usr_email: "",
        usr_suc:""
      },
      formUser: {
        usr_id: "",
        usr_name: "",
        usr_code: "",
        usr_box_vol: "",
        usr_box_pr: "",
        usr_price: "",
        usr_handler: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD"),
        usr_sex: "",
        usr_avatar: "",
        usr_address: "",
        usr_email: "",
        usr_password: "",
        usr_password2: "",
      },
      dataSetting: {
        set_name: "",
        set_price_manejo: "",
        set_price_tarjeta: "",
      },
      dataCalculator: {
        tr_price: "",
      },
      dataTracks: [],
      dataOrders: [],
      loading: false,
      activeTab: "1",
      isPrinting: false,
      isFilter: false,
      isOpenProfile: false,
      isOpenModalAvatar: false,
      isOpenModalEAereo: false,
    };
    this.fetchUserAccount = this.fetchUserAccount.bind(this);
    this.fetchTrackingAccount = this.fetchTrackingAccount.bind(this);
    this.fetchSettingAccount = this.fetchSettingAccount.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleEstatus = this.toggleEstatus.bind(this);
    this.iPrintBody = this.iPrintBody.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.print = this.print.bind(this);
    this.filter = this.filter.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
    this.handleModalProfile = this.handleModalProfile.bind(this);
    this.handleModalProfileOpen = this.handleModalProfileOpen.bind(this);
    this.onchangeFormProfile = this.onchangeFormProfile.bind(this);
    this.toggleSaveProfile = this.toggleSaveProfile.bind(this);
    this.toggleHandler = this.toggleHandler.bind(this);
    this.toggleHandler2 = this.toggleHandler2.bind(this);
    this.toggleHandlerLibra = this.toggleHandlerLibra.bind(this);
    this.toggleHandlerPrice = this.toggleHandlerPrice.bind(this);
    this.toggleHandlerQty = this.toggleHandlerQty = this.toggleHandlerQty.bind(this);
    this.fetchOrderAccount = this.fetchOrderAccount.bind(this);
    this.toggleEstatusOrder_mobile = this.toggleEstatusOrder_mobile.bind(this);
    this.handleCloseModalAvatar = this.handleCloseModalAvatar.bind(this);
    this.onChangeCalculator = this.onChangeCalculator.bind(this);
    this.handlerModalExampleAereo = this.handlerModalExampleAereo.bind(this);
  }

  print() {
    this.setState({ isPrinting: true });
  }

  iPrintBody = () => {
    const props = this.state;
    return (
      <React.Fragment>
        <Row className="mb-5" key={new Date().toString()}>
          <Col xs={2}></Col>
          <Col xs={8} className="text-center mb-4">
            <img alt="" className="logo-school-informe" src={LOGO_ICON}></img>
          </Col>
          <Col xs={2}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-left mb-3">
            <Label className="font-weight-bold">
              FECHA DE IMPRESION:{" "}
              {moment(new Date()).format("YYYY-MM-DD")}
            </Label>{" "}
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-left mb-3">
            <Label className="font-weight-bold">
              USUARIO: {props.dataUser.usr_name} - {props.dataUser.usr_code}
            </Label>{" "}
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-center border-print">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center w-350px">TRACKING</th>
                  <th className="text-center">PESO</th>
                  <th className="text-center">PRECIO</th>
                  <th className="text-center w-250px">FECHA DE ENTREGA</th>
                </tr>
              </thead>
              <tbody>
                {props.dataTracks.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-left font-weight-bold">{i}</td>
                      <td className="text-left">{data.tr_track}</td>
                      <td className="text-center">{data.tr_libra}</td>
                      <td className="text-right">
                        {"$ " + parseFloat(data.tr_price).toFixed(2)}
                      </td>
                      <td className="text-center">{data.tr_date_delivered}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </React.Fragment>
    );
  };

  async fetchSettingAccount(usr_suc) {
    const response = await getSettingAccount(usr_suc);
    var set_name ="";
    var set_price_manejo ="";
    var set_price_tarjeta ="";

    if(response.length>0){
     set_name =response[0].set_name;
     set_price_manejo =Number(response[0].set_price_manejo).toFixed(2);
     set_price_tarjeta =response[0].set_price_tarjeta;
    }

    this.setState({
      dataSetting: {
        set_name: set_name,
        set_price_manejo: set_price_manejo,
        set_price_tarjeta: set_price_tarjeta,
      },
      loading: false,
    });
  }

  async fetchUserAccount() {
    const response = await getUserAccount(this.context.usr_id);
    this.setState({
      dataUser: {
        usr_id: response[0].usr_id,
        usr_name: response[0].usr_name,
        usr_code: response[0].usr_code,
        usr_box_vol: response[0].usr_box_vol,
        usr_box_pr: response[0].usr_box_pr,
        usr_price: response[0].usr_price,
        usr_handler: response[0].usr_handler,
        usr_birthday: response[0].usr_birthday,
        usr_sex: response[0].usr_sex,
        usr_avatar: response[0].usr_avatar,
        usr_address: response[0].usr_address,
        usr_email: response[0].usr_email,
        usr_suc:response[0].usr_suc
      },
      loading: false,
      isOpenProfile: false,
    });
    this.fetchSettingAccount(response[0].usr_suc);
  }

  async fetchOrderAccount() {
    const response = await getOrderAccount(this.context.usr_id);
    this.setState({
      dataOrders: response,
      loading: false
    });
  }

  async fetchTrackingAccount() {
    const response = await getTrackingAccount(this.context.usr_id);
    this.setState({
      dataTracks: response,
      loading: false,
      isFilter: false,
      isPrinting: false,
    });
  }

  toggle(activeTab) {
    if (activeTab === "2") {
      this.fetchOrderAccount();
    }
    this.setState({
      activeTab,
    });
  }

  componentDidMount() {
    this.fetchUserAccount();
    this.fetchTrackingAccount();
 
  }

  closeFilter() {
    this.fetchTrackingAccount();
  }

  filter() {
    const props = this.state;
    let result = props.dataTracks.filter(function (e) {
      return e.tr_date_delivered === null;
    });
    this.setState({ dataTracks: result, isFilter: true });
  }

  cerrarSession() {
    this.context.quitToken();
  }

  handleGoBack() {
    this.setState({ isPrinting: false });
  }

  clipboard(text) {
    navigator.clipboard.writeText(text);
  }

  toggleHandlerLibra(column, columnIndex) {
    const props = this.state.dataTracks;
    var peso = 0;
    props.map((data, i) => {
      peso = peso + Number(data.tr_libra);
      return null;
    });
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Label> {peso}</Label>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  toggleHandlerPrice(column, columnIndex) {
    const props = this.state.dataTracks;
    var price = 0;
    props.map((data, i) => {
      price = price + Number(data.tr_price);
      return null;
    });
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Label> $ {price}</Label>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  toggleHandlerQty(column, columnIndex) {
    const props = this.state.dataTracks;

    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-left">
            <Label> CANTIDAD DE PAQUETES : {props.length}</Label>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
  toggleHandler2(column, columnIndex) {
    const props = this.state.dataTracks;
    var peso = 0;
    var price = 0;
    props.map((data, i) => {
      peso = peso + Number(data.tr_libra);
      price = price + Number(data.tr_price);
      return null;
    });
    return (
      <React.Fragment>
        <Row>
          <Col xs={5} className="text-left">
            <Label>PAQUETES : {props.length}</Label>
          </Col>
          <Col xs={4} className="text-left">
            <Label> PESO : {peso}</Label>
          </Col>
          <Col xs={3} className="text-left">
            <Label> $ {price}</Label>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
  toggleEstatusOrder_mobile(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col
            xs={12}
            className={
              row.ord_delivered === "1"
                ? "text-center alert-success-div"
                : "text-center alert-waiting-div"
            }
          >
            {row.ord_delivered === "1" ? (
              <React.Fragment>
                <Label className="font-weight-bold label-green">
                  ENTREGADO{" "}
                </Label>
              </React.Fragment>
            ) : (
              <Label className="font-weight-bold track-label2">
                {row.ord_date_deliverd === null ? "ACTIVO" : "DISPONIBLE"}
              </Label>
            )}
          </Col>
          <Col xs={12} className="text-left">
            <Label className="font-weight-bold">Track : </Label>
            <Label className="track-label">
              {` ${row.ord_track}`}
              {row.ord_delivered !== "0" ? (
                <img alt="" className="img-track" src={CHECK}></img>
              ) : (
                <img alt="" className="img-track" src={INFO}></img>
              )}
            </Label>
          </Col>
          <Col xs={12} className="text-left">
            <Label className="track-label">
              <a
                className="label-link"
                target="_blank"
                rel="noopener noreferrer"
                href={row.ord_link}
              >
                {row.ord_desc}
              </a>
            </Label>
          </Col>
          <Col xs={9} className="text-left">
            {row.ord_delivered === "1" ? (
              <React.Fragment>
                <Label className="font-weight-bold">Entregado : </Label>
                <Label className="track-price">
                  {moment(new Date(row.ord_date_client)).format("YYYY-MM-DD")}
                </Label>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Label className="font-weight-bold">Ordenado : </Label>
                <Label className="track-price">
                  {moment(new Date(row.ord_date_order)).format("YYYY-MM-DD")}
                </Label>{" "}
              </React.Fragment>
            )}
          </Col>
          <Col xs={3} className="text-left">
            <Label className="font-weight-bold">{row.suc_desc}</Label>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
  toggleHandler(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col
            xs={12}
            className={
              row.tr_date_delivered !== null
                ? "text-center alert-success-div"
                : "text-center alert-waiting-div"
            }
          >
            {row.tr_date_delivered !== null ? (
              <React.Fragment>
                <Label className="font-weight-bold label-green">
                  ENTREGADO{" "}
                </Label>
              </React.Fragment>
            ) : (
              <Label className="font-weight-bold track-label2">
                DISPONIBLE
              </Label>
            )}
          </Col>
          <Col xs={12} className="text-left">
            <Label className="font-weight-bold">Track : </Label>
            <Label className="track-label">
              {` ${row.tr_track}`}
              {row.tr_date_delivered !== null ? (
                <img alt="" className="img-track" src={CHECK}></img>
              ) : (
                <img alt="" className="img-track" src={INFO}></img>
              )}
            </Label>
          </Col>
          <Col xs={3} className="text-left">
            <Label className="font-weight-bold">Peso : </Label>
            <Label className="track-label">{` ${row.tr_libra}`}</Label>
          </Col>
          <Col xs={6} className="text-left">
            <Label className="font-weight-bold">Precio : </Label>{" "}
            <Label className="track-price">
              {`$ ${parseFloat(row.tr_price).toFixed(2)}`}{" "}
            </Label>
          </Col>
          <Col xs={3} className="text-left">
            <Label className="font-weight-bold">{row.suc_desc}</Label>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  toggleEstatusOrder(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            {row.ord_delivered === "1" ? (
              <Label className="font-weight-bold label-green">Entregado </Label>
            ) : (
              <React.Fragment>
                {row.ord_date_deliverd !== null ? (
                  <Label className="track-label2">Disponible</Label>
                ) : (
                  <Label className="track-label2">Activo</Label>
                )}
              </React.Fragment>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  toggleEstatus(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            {row.tr_date_delivered !== null ? (
              <Label className="font-weight-bold label-green">Entregado </Label>
            ) : (
              <Label className="track-label2">Disponible</Label>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  onChangeCalculator(e) {
    const props = this.state;
    if (e.target.value.length > 0) {
      if (props.dataUser.usr_handler === "1") {
        this.setState({
          dataCalculator: {
            tr_price:
              Number(props.dataUser.usr_price) * Number(e.target.value) +
              Number(props.dataSetting.set_price_manejo),
          },
        });
      } else {
        this.setState({
          dataCalculator: {
            tr_price: Number(props.dataUser.usr_price) * Number(e.target.value),
          },
        });
      }
    } else {
      this.setState({
        dataCalculator: {
          tr_price: 0,
        },
      });
    }
  }

  onchangeFormProfile(e) {
    this.setState({
      formUser: {
        ...this.state.formUser,
        [e.target.name]: e.target.value,
      },
    });
  }
  
  handlerModalExampleAereo = () => {
    this.setState((prevState) => ({
      isOpenModalEAereo: !prevState.isOpenModalEAereo,
      msg_error: "",
    }));
  };

  handleModalProfileOpen = () => {
    this.setState((prevState) => ({
      isOpenProfile: true,
      formUser: this.state.dataUser,
      msg_error: "",
    }));
  };

  handleModalProfile = () => {
    this.setState((prevState) => ({
      isOpenProfile: false,
      formUser: {
        usr_id: "",
        usr_name: "",
        usr_code: "",
        usr_box_vol: "",
        usr_box_pr: "",
        usr_price: "",
        usr_handler: "",
        usr_birthday: "",
        usr_sex: "",
        usr_avatar: "",
        usr_address: "",
        usr_email: "",
        usr_password: "",
        usr_password2: "",
      },
      msg_error: "",
    }));
  };

  toggleSaveProfile() {
    const props = this.state.formUser;
    if ((props.usr_password = props.usr_password2)) {
      if (props.usr_name.length > 3) {
        const body = {
          usr_id: props.usr_id,
          usr_birthday: moment(new Date(props.usr_birthday)).format(
            "YYYY-MM-DD"
          ),
          usr_name: props.usr_name,
          usr_sex: props.usr_sex,
          usr_address: props.usr_address,
          usr_email: props.usr_email,
          usr_password: props.usr_password,
        };
        putProfile(body);
        setTimeout(() => {
          this.fetchUserAccount();
        }, 1000);
      } else {
        this.setState({
          msg_error: "Campos vacios",
        });
      }
    } else {
      this.setState({
        msg_error: "Contraseñas diferentes",
      });
    }
  }
  handleCloseModalAvatar() {
    this.setState((prevState) => ({
      isOpenModalAvatar: !prevState.isOpenModalAvatar,
    }));
  }

  render() {
    const props = this.state;
    var fecha1 = moment(new Date(props.dataUser.usr_birthday));
    var fecha2 = moment(new Date());
    var sex = "Man";
    var defaultAvatar = "m.png";
    var edad = fecha2.diff(fecha1, "years");
    if (props.dataUser.usr_avatar.length > 0) {
      defaultAvatar = props.dataUser.usr_avatar;
    } else {
      if (props.dataUser.usr_sex === "2") {
        sex = "Women";
        defaultAvatar = "w";
      }
      if (edad < 30) {
        if (sex === "Man") defaultAvatar = "m.png";
        else defaultAvatar = "w.png";
      } else if (edad < 40) {
        if (sex === "Man") defaultAvatar = "m_30.png";
        else defaultAvatar = "w_30.png";
      } else if (edad < 60) {
        if (sex === "Man") defaultAvatar = "m_40.png";
        else defaultAvatar = "w_40.png";
      } else if (edad < 100) {
        if (sex === "Man") defaultAvatar = "m_60.png";
        else defaultAvatar = "w_60.png";
      }
    }

    return (
      <React.Fragment>
        {props.isOpenModalAvatar && (
          <ChangeAvatar
            isOpen={props.isOpenModalAvatar}
            toggle={this.handleCloseModalAvatar}
            IMG_AVATAR={defaultAvatar}
            nameImg={props.dataUser.usr_id}
            urlHttp={urlHttpPostAvatar}
          />
        )}

        {props.isOpenProfile && (
          <PROFILE
            onChange={this.onchangeFormProfile}
            isOpen={props.isOpenProfile}
            toggle={this.handleModalProfile}
            toggleSave={this.toggleSaveProfile}
            dataFormUser={props.formUser}
            msg_error={props.msg_error}
            defaultAvatar={defaultAvatar}
          />
        )}
        {props.isOpenModalEAereo && (
          <ModalExampleA
            isOpen={props.isOpenModalEAereo}
            toggle={this.handlerModalExampleAereo}
            dataF={props.dataUser}
            toggleCopy={  this.clipboard(props.dataUser.usr_box_pr)}
          />
        )}
        <Row className="profile-panel-p mb-3">
          <Col xs={12}>
            <div className="card hovercard">
              <div className="card-background">
                <img
                  className="card-bkimg"
                  alt=""
                  src={urlFiles + "/avatars/" + defaultAvatar}
                />
              </div>
              <div className="useravatar">
                <img
                  alt=""
                  src={urlFiles + "/avatars/" + defaultAvatar}
                  onClick={() => {
                    this.handleCloseModalAvatar();
                  }}
                />
              </div>
              <div className="card-info">
                <span className="card-title">
                  {props.dataUser.usr_name.toUpperCase()} -{" "}
                  {props.dataUser.usr_code.toUpperCase()}
                </span>
              </div>
            </div>
            <Row>
              <Col xs={12} className="text-center mt-3">
                <div className="profile-userbuttons">
                  <Button
                    color="success"
                    onClick={() => {
                      this.handleModalProfileOpen();
                    }}
                  >
                    Perfil
                  </Button>

                  <Button
                    color="primary"
                    className="ml-3"
                    onClick={() => {
                      this.cerrarSession();
                    }}
                  >
                    Cerrar sesión
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="mb-4 mt-2">
              <Col xs={12} sm={6}>
                <Card body>
                  <CardHeader tag="h3">Tu Dirección P.O Box Aérea</CardHeader>
                  <CardBody>
                    <CardTitle tag="h6">
                      <b>
                        {" "}
                        ${Number(props.dataUser.usr_price).toFixed(2)} x Lb.
                        peso real 
                        {props.dataUser.usr_handler === "1" ? (
                          <React.Fragment>
                            &nbsp; y $ {props.dataSetting.set_price_manejo} {" "}
                            
                             manejo por paquete.{" "}
                          </React.Fragment>
                        ) : null}
                      </b>
                    </CardTitle>
                    <CardText>{props.dataUser.usr_box_pr}</CardText>
                    <Row>
                      <Col xs={12} sm={6} className="text-center mt-2">
                      <Button
 color="primary"
                      className="w-50 m-auto"
                      onClick={() => {
                        this.clipboard(props.dataUser.usr_box_pr);
                      }}
                    >
                      Copiar
                    </Button>
                      </Col>
                      <Col xs={12} sm={6} className="text-center mt-2">
                      <Button
                       color="success"
                      className="w-50 m-auto"
                      onClick={() => {
                        this.handlerModalExampleAereo();
                      }}
                    >
                      Ejemplo
                    </Button>
                      </Col>
                    </Row>
                   
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} sm={6}>
                {props.dataUser.usr_box_vol.length > 0 ? (
                  <Card body>
                    <CardHeader tag="h3">
                      Tu Dirección P.O Box Marítimo
                    </CardHeader>
                    <CardBody>
                      <CardText>{props.dataUser.usr_box_vol}</CardText>
                      <Row>
                      <Col xs={12} className="text-center">
                      <Button
                        className="w-50 m-auto"
                        onClick={() => {
                          this.clipboard(props.dataUser.usr_box_vol);
                        }}
                        color="primary"
                      >
                        Copiar
                      </Button>
                      </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ) : null}
              </Col>
              <Col xs={12}>
                <Card>
                  <CardHeader tag="h3">Métodos de pago</CardHeader>
                  <CardBody>
                    <CardText>
                      Banco General, Global Bank, Banco Nacional, Yappy, Punto
                      de venta tarjetas.
                    </CardText>
                    <img
                      alt=""
                      src={BGENERAL}
                      className="payment-method-icon"
                    />
                    <img alt="" src={YAPPY} className="payment-method-icon" />
                    <img
                      alt=""
                      src={BANCO_NACIONAL}
                      className="payment-method-icon"
                    />
                    <img
                      alt=""
                      src={GLOBAL_BANK}
                      className="payment-method-icon"
                    />
                    <img
                      alt=""
                      src={PORT_TERMINAL}
                      className="payment-method-icon"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: props.activeTab === "1" })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    <Label className="font-weight-bold font-size-12px">
                      Paquetes
                    </Label>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: props.activeTab === "2" })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    <Label className="font-weight-bold font-size-12px">
                      Pedidos
                    </Label>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: props.activeTab === "3" })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    <Label className="font-weight-bold font-size-12px">
                      Cotizaciones
                    </Label>
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: props.activeTab === "4" })}
                    onClick={() => {
                      this.toggle("4");
                    }}
                  >
                    <Label className="font-weight-bold font-size-12px">
                      17Track
                    </Label>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: props.activeTab === "5" })}
                    onClick={() => {
                      this.toggle("5");
                    }}
                  >
                    <Label className="font-weight-bold font-size-12px">
                      Calculadora
                    </Label>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={props.activeTab}>
                <TabPane tabId="1" className="overflow-auto">
                  <Row>
                    <Col xs={12} className="text-center mt-2">
                      <h5 className="font-weight-bold">PAQUETES</h5>
                    </Col>
                    <Col xs="12" className="text-center">
                      {props.isPrinting ? (
                        <React.Fragment>
                          <Row className="mt-3">
                            <Col xs={12} className="text-right">
                              <Button
                                color="primary"
                                onClick={() => this.handleGoBack()}
                              >
                                Cerrar
                              </Button>
                            </Col>
                            <Col xs={12}>
                              <REACT_PRINT_COMPONENT body={this.iPrintBody()} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Row>
                            {props.isFilter ? (
                              <Col xs={8} className="text-left"></Col>
                            ) : (
                              <Col xs={8} className="text-left mt-3">
                                <Button
                                  color="primary"
                                  onClick={() => this.filter()}
                                >
                                  Paquetes por retirar
                                </Button>
                              </Col>
                            )}

                            {props.isFilter ? (
                              <Col xs={4} className="text-right mt-3">
                                <Button
                                  color="primary"
                                  onClick={() => this.closeFilter()}
                                >
                                  Cerrar
                                </Button>
                              </Col>
                            ) : (
                              <Col xs={4} className="text-right mt-3">
                                <Button
                                  color="success"
                                  onClick={() => this.print()}
                                >
                                  PDF
                                </Button>
                              </Col>
                            )}

                            <Col xs={12} className="text-center">
                              {window.screen.width < 600 ? (
                                props.isFilter ? (
                                  <Datatable
                                    data={props.dataTracks}
                                    columns={COLUMNS_TRACKS_ACCOUNT_FILTER_MOBILE(
                                      this.toggleHandler,
                                      this.toggleHandler2
                                    )}
                                    keyProp="tr_id"
                                    exportD={false}
                                    searchs={false}
                                    selectRows={false}
                                    optionSelectRow={null}
                                  />
                                ) : (
                                  <Datatable
                                    data={props.dataTracks}
                                    columns={COLUMNS_TRACKS_ACCOUNT_MOBILE(
                                      this.toggleHandler
                                    )}
                                    keyProp="tr_id"
                                    exportD={false}
                                    searchs={false}
                                    selectRows={false}
                                    optionSelectRow={null}
                                  />
                                )
                              ) : props.isFilter ? (
                                <Datatable
                                  data={props.dataTracks}
                                  columns={COLUMNS_TRACKS_ACCOUNT_FILTER(
                                    this.toggleEstatus,
                                    this.toggleHandlerLibra,
                                    this.toggleHandlerPrice,
                                    this.toggleHandlerQty
                                  )}
                                  keyProp="tr_id"
                                  exportD={false}
                                  searchs={true}
                                  selectRows={false}
                                  optionSelectRow={null}
                                />
                              ) : (
                                <Datatable
                                  data={props.dataTracks}
                                  columns={COLUMNS_TRACKS_ACCOUNT(
                                    this.toggleEstatus
                                  )}
                                  keyProp="tr_id"
                                  exportD={false}
                                  searchs={true}
                                  selectRows={false}
                                  optionSelectRow={null}
                                />
                              )}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col xs={12} className="text-center mt-2">
                      <h5 className="font-weight-bold">PEDIDOS</h5>
                    </Col>
                    <Col xs={12}>
                      {window.screen.width < 600 ? (
                        <Datatable
                          data={props.dataOrders}
                          columns={COLUMNS_ORDERS_ACCOUNT_MOBILE(
                            this.toggleEstatusOrder_mobile
                          )}
                          keyProp="ord_id"
                          exportD={false}
                          searchs={false}
                          selectRows={false}
                          optionSelectRow={null}
                        />
                      ) : (
                        <Datatable
                          data={props.dataOrders}
                          columns={COLUMNS_ORDERS_ACCOUNT(
                            this.toggleEstatusOrder
                          )}
                          keyProp="ord_id"
                          exportD={false}
                          searchs={true}
                          selectRows={false}
                          optionSelectRow={null}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col xs={12} className="text-center mt-2">
                      <h5 className="font-weight-bold">COTIZACIONES</h5>
                    </Col>
                    <Col xs={12}>
                      {window.screen.width < 600 ? (
                        <Datatable
                          data={props.dataOrders}
                          columns={COLUMNS_ORDERS_ACCOUNT_MOBILE(
                            this.toggleEstatusOrder_mobile
                          )}
                          keyProp="ord_id"
                          exportD={false}
                          searchs={false}
                          selectRows={false}
                          optionSelectRow={null}
                        />
                      ) : (
                        <Datatable
                          data={props.dataOrders}
                          columns={COLUMNS_ORDERS_ACCOUNT(
                            this.toggleEstatusOrder
                          )}
                          keyProp="ord_id"
                          exportD={false}
                          searchs={true}
                          selectRows={false}
                          optionSelectRow={null}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col xs={12} className="text-center mt-2">
                      <h5 className="font-weight-bold">17TRACK</h5>
                    </Col>
                    <Col xs={12}>
                      {window.screen.width < 650 ? (
                        <div className="prueba1">
                          <iframe
                          title="Iframe2"
                            scrolling="no"
                            src="https://extcall.17track.net/es"
                            className="prueba2"
                          />
                        </div>
                      ) : (
                        <iframe
                          src={"https://www.17track.net/es"}
                          height={"900"}
                          width={"100%"}
                          title="Iframe"
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <Col xs={12} className="text-center mt-2">
                      <h5 className="font-weight-bold ">
                        CALCULADORA DE FLETE
                      </h5>
                    </Col>
                    <Col xs={12}>
                      <b>Indicaciones para la calculadora de flete:</b>
                      <br></br>
                      <br></br>
                      <li>
                        Los resultados aquí mostrados no representan una
                        cotización formal, ni el precio final de la compra. Los
                        costos finales de la dependerán del peso final del
                        artículo, el cual será pesado y procesado cuando se
                        reciba en las bodegas de {props.dataSetting.set_name.toUpperCase()}.
                      </li>
                      <br></br>
                      <li>
                        Si quieres que {props.dataSetting.set_name.toUpperCase()} haga el pedido por ti. Se
                        cobrara $
                        {Number(props.dataSetting.set_price_tarjeta).toFixed(2)}{" "}
                        adicional.
                      </li>
                    </Col>
                    <Col xs={12}>
                      <br></br>
                      <FormGroup>
                        <Label className="font-weight-bold">Peso real: </Label>

                        <Input
                          type="number"
                          name="tr_peso"
                          id="tr_peso"
                          placeholder="Peso real"
                          onChange={this.onChangeCalculator}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold">
                          Costo Aéreo estimado:{" "}
                        </Label>
                        <Input
                          type="number"
                          name="tr_price"
                          id="tr_price"
                          value={Number(props.dataCalculator.tr_price).toFixed(
                            2
                          )}
                          placeholder="0.0 $"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Profile;
