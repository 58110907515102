import React, { Component } from "react";
import { HomeimageSlider } from "./../constant/Util";
import { Row, Col } from "reactstrap";
import Iframe from "react-iframe";
import StoreContext from "./../../Store/Context";
import "../../assets/css/main.css";
import { url, postRegisterUser, validateUser,service_id,template_id,user_id,template_idNot, getUserDataAdmin,sendEmail,USR_SUC } from "./../constant/Services";
import moment from "moment";
import History from "./../constant/History";
import { LOGIN, OkModal } from "./../constant/Modal";
import Footer from "./../Menu/Footer";
import {
  OUR_TEAM_ICON,
  KERLYS_ICON,
  CORONA_SDK_ICON,
  JAVASCRIPT_ICON,
  CODEIGNITHER_ICON,
  BOOSTRAP_ICON,
  REACT_ICON,
  PERSON_ICON,
  ATENTION_ICON,
  EXPERIENCE_ICON,
  QUALITY_ICON,
  UNAMED_ICON,
  DESARROLLO_APPS,
  DEPARTAMENTO_VENTAS,
  DESARROLLO_WEB,
  LAB_GUADALUPE,
  MAX_PERFORMANCE,
  AP_BOX,
  ORACLE_ICON,
  DATABASE_ICON,
  LEOTECH,
  PEOPLE,
  EMAIL,
  APPS_ICON,
  AVION_ICON,
  SEARCH,
  OPABOGADO,
  KINGS,
  PROMO,
  GEOVANY_CABALLERO,R1,R2,R3,R4,R5,R6,R7,R8,R9,R10,R11,R12,R13,R14,R15,R16,R17,R18,R19,R20
} from "../constant/Index.js";
import Button from "reactstrap/lib/Button";

class Home extends Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      children: props.children,
      loginOpen: false,
      isCollapse: false,
      okModal: false,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      dataSetting:{
        usr_name: "",
        set_price_libra: "",
        set_price_manejo: "",
        set_direccion: "",
        set_celphone: "",
        set_email: "",
        set_address:""
      },
      isRegisterOpen:true,
      msg_error: "",
    };

    this.handleModalLogin = this.handleModalLogin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.login = this.login.bind(this);
    this.onChangeFLogin = this.onChangeFLogin.bind(this);
    this.setError = this.setError.bind(this);
    this.handlerRegister = this.handlerRegister.bind(this);
    this.toggleSaveRegister = this.toggleSaveRegister.bind(this);
    this.seTypeSex = this.seTypeSex.bind(this);
    this.fetchValidateUser = this.fetchValidateUser.bind(this);
    this.handlerOkModal = this.handlerOkModal.bind(this);
    this.fetchSetting = this.fetchSetting.bind(this);
  }
  async login({ user, password }) {
    if (user.length > 0 && password.length > 0) {
      const dataBody = {
        user,
        password,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(dataBody),
      };
      const response = await fetch(
        url + "LoginController/login",
        requestOptions
      );
  
      const result = await response.json();

      if (result[0].count !== "0") {
        return {
          token: true,
          usr_id: result[0].usr_id,
          usr_name: result[0].usr_name,
          usr_type: result[0].usr_type,
        };
      } else {
        return { error: "Usuario o contraseña incorrectos" };
      }
    } else {
      return { error: "Campos estan vacios" };
    }
  }

  onChangeFLogin(e) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }

  async onSubmit(event) {
    event.preventDefault();
    const props = this.state.dataFLogin;
    const { token, usr_id, usr_name, usr_type, error } = await this.login({
      user: props.celphone,
      password: props.password,
    });

    if (token) {
      this.context.quitToken();
      this.context.setToken({ token, usr_id, usr_name, usr_type });
      if (usr_type === "2") {
        return History.push("/admin");
      } else {
        this.setState({ isRegisterOpen: false, loginOpen: false });
        return History.push("/profile/account");
      }
    }
    this.setError(error);
  }

  handlerOkModal() {
    this.setState((prevState) => ({
      okModal: !prevState.okModal,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    }));
  }

  handleModalLogin() {
    this.setState((prevState) => ({
      loginOpen: !prevState.loginOpen,
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
      isCollapse: false,
    }));
    this.fetchSetting();
  }
  setError(error) {
    this.setState({
      msg_error: error,
    });
  }
  async fetchSetting() {
    const response = await getUserDataAdmin(USR_SUC);
    this.setState({
      dataSetting: {
        usr_name: response[0].usr_name,
        set_price_libra: response[0].set_price_manejo,
        set_price_manejo: response[0].set_price_manejo,
        set_direccion:response[0].set_direccion,
        set_celphone: response[0].set_celphone,
        set_email: response[0].set_email,
        set_address: response[0].set_address,
      },
      loading: false,
      isSaving: false,
    });
  }

  componentDidMount() {
    this.context.quitToken();
  }

  async fetchValidateUser(usr, pass) {
    const props = this.state.dataFLogin;
    const propsSetting = this.state.dataSetting;
    const response = await validateUser(usr, pass);
    if (response.exist) {
      this.setState({
        msg_error: "Tenemos una cuenta registrada con ese numero de teléfono",
      });
    } else {
      var usr_code_ =
        response.set_siglas[0].set_siglas +
        this.paddy(response.n_box[0].n_box, 5);
      const body = {
        usr_code: usr_code_,
        usr_box_pr:
          usr_code_ +
          " " +
          props.usr_name.toUpperCase() +
          " \n" +
          propsSetting.set_address,
        usr_price: response.set_price_libra[0].set_price_libra,
        usr_name: props.usr_name.toUpperCase(),
        usr_user: props.usr_celphone,
        usr_celphone: props.usr_celphone,
        usr_password: props.usr_password1,
        usr_email: props.usr_email,
        usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
        usr_sex: props.usr_sex,
        usr_suc:USR_SUC
      };
      
      postRegisterUser(body);
      var dataEmail = {
        service_id: service_id,
        template_id: template_id,
        user_id: user_id,
        template_params: {
          from_user:propsSetting.usr_name,
          to_user:props.usr_name,
          set_address:propsSetting.set_direccion,
          to_email:props.usr_email,
          set_price:propsSetting.set_price_libra,
          set_handler:propsSetting.set_price_manejo,
          usr_code:usr_code_+ " " + props.usr_name.toUpperCase(),
          usr_box: usr_code_ + " " + props.usr_name.toUpperCase() + " \n" +
          propsSetting.set_address,
          set_phone:propsSetting.set_celphone,
          set_email:propsSetting.set_email,
        },
      };

      sendEmail(dataEmail);

      var dataEmailNot = {
        service_id: service_id,
        template_id: template_idNot,
        user_id: user_id,
        template_params: {
          from_user:propsSetting.usr_name,
          to_email:propsSetting.set_email,
          usr_cliente: props.usr_name
        },
      };
      
      sendEmail(dataEmailNot);
      this.setState({
        isRegisterOpen: false,
        loginOpen: false,
        okModal: true,
      });
    }
  }

  paddy(num, padlen, padchar) {
    var pad_char = typeof padchar !== "undefined" ? padchar : "0";
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  toggleSaveRegister() {
    const props = this.state.dataFLogin;
    if (
      props.usr_name.length > 3 &&
      props.usr_email.length > 0 &&
      props.usr_birthday.length > 0 &&
      props.usr_celphone.length > 0 &&
      props.usr_password1.length > 0 &&
      props.usr_password2.length > 0
    ) {
      if (props.usr_password1 === props.usr_password2) {
        this.fetchValidateUser(props.usr_celphone, props.usr_password1);
      } else {
        this.setState({
          msg_error: "Contraseñas diferentes",
        });
      }
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  handlerRegister() {
    this.setState({
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    });
  }

  seTypeSex({ usr_sex }) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        usr_sex,
      },
    });
  }
  render() {
    const props = this.state;
    return (
      <React.Fragment>
        {props.okModal && (
          <OkModal
            isOpen={props.okModal}
            toggle={this.handlerOkModal}
            usr_name={props.dataFLogin.usr_name}
          />
        )}
        {props.loginOpen && (
          <LOGIN
            onChange={this.onChangeFLogin}
            isOpen={props.loginOpen}
            toggle={this.handleModalLogin}
            toggleSave={this.onSubmit}
            toggleSaveRegister={this.toggleSaveRegister}
            dataFTeacher={props.dataFLogin}
            msg_error={props.msg_error}
            isRegisterOpen={props.isRegisterOpen}
            handlerRegister={this.handlerRegister}
            seTypeSex={this.seTypeSex}
          />
        )}

        {/* <div className="container mt-4">
          <div className="text-center">
            <div className="error-page">
              <Button color="success" onClick={this.handleModalLogin}>Registrarme</Button>{" "}
            </div>
          </div>
        </div> */}
        <div className=" mt-3 mb-3 text-center">
          {/* <img src={POBOX} className="img-pobox" alt="POPBOX"></img>

            <img src={AVION} className="img-pobox mt-2" alt="POPBOX"></img> */}

          {/* <HomeimageSlider /> */}
        </div>
        {/* <section id="service-block-main" className="section">
          <div className="container">
            <Row>
              <div className="col-sm-12 col-md-12 mb-3 text-center">
                <h1 className=" wow fadeIn animated mb-3" data-wow-delay=".2s">
                  COMPRAS POR INTERNET
                </h1>
                <p className="text-justify">
                  <strong>
                    <b>CASILLERO (P.O. BOX) EN MIAMI</b>
                  </strong>{" "}
                  <br></br>
                  Si Tienes tarjeta de crédito y deseas realizar tus compras?
                  ¡No hay problema! Envía tus productos a tu casillero en Miami
                  y nosotros nos encargamos del resto. No cobramos peso
                  volumétrico. Contacta con nosotros y adquiere tu casillero
                  totalmente gratis. <br></br>
                  ¡Pero! ¿no tienes tarjeta de crédito? No te preocupes,
                  nosotros hacemos la compra por ti y te asesoramos en tus
                  compras.
                </p>
              </div>
            </Row>
            <Row>
              <Col xs={12}></Col>
              <Col xs={6} className="mt-1">
                Carga <b>Aérea</b>
                <img alt="" src={AVION_ICON} className="icon-compras"></img>
                <br></br>
                <p>
                  Este servicio facilita el transporte de todo tipo de paquetes
                  y mercancías, desde 1 libra hasta envíos con peso elevado.
                </p>
              </Col>
              <Col xs={6} className="mt-1">
                <b>Corporativos</b>
                <img alt="" src={PEOPLE} className="icon-compras"></img>
                <br></br>
                <p>
                  Planes diseñados para empresas con soluciones logísticas,
                  tarifas especiales, trámites aduanales y transporte local.
                </p>
              </Col>
              <Col xs={6} className="mt-4">
                <b>Rastreo</b>
                <img alt="" src={SEARCH} className="icon-compras"></img>
                <br></br>
                <p>
                  Ingresando a tu cuenta podrás darle seguimiento a tus
                  paquetes.
                </p>
              </Col>
              <Col xs={6} className="mt-4">
                <b>Notificaciones</b>
                <img alt="" src={EMAIL} className="icon-compras"></img>
                <br></br>
                <p>
                  Enviamos correos cuando tu carga esta lista para ser retirada.
                </p>
              </Col>
              <Col xs={6} className="mt-4">
                APP <b>Clientes</b>
                <img alt="" src={APPS_ICON} className="icon-compras"></img>
                <br></br>
                <p>
                  Hemos creado un sistema online en donde podrás ver estado de
                  tus paquetes, revisar facturas, cotizar y más...
                </p>
              </Col>
              <Col xs={6} className="mt-4"></Col>
            </Row>
            <Row>
              <div className="col-sm-12 col-md-12 text-center mt-5">
                <h1 className=" wow fadeIn animated" data-wow-delay=".2s">
                  ¿POR QUÉ ELEGIRNOS?
                </h1>
              </div>
              <div className="col-sm-12 col-md-12">
                <ul className="vineta mt-5">
                  <li>
                    <p className="section-subcontent">
                      Por nuestra efectividad, aseguramos resultados;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque realizamos proyectos personalizados a cada cliente;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque aseguramos el cumplimiento de los plazos
                      establecidos;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque tratamos a cada cliente como si fuera el único;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque su empresa y su proyecto son importantes para
                      nosotros;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque nos implicamos al cien por cien en cada proyecto;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque somos flexibles, nos adaptamos a las necesidades de
                      su empresa;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque trabajamos colaborativamente con nuestros clientes.
                      ¡Eres parte de nuestra empresa!
                    </p>
                  </li>
                </ul>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item wow fadeInUpQuick animated"
                  data-wow-delay=".5s"
                >
                  <div className="icon-wrapper">
                    <img src={QUALITY_ICON} alt="Quality"></img>
                  </div>
                  <h2>POR CALIDAD</h2>
                  <p className="text-justify">
                    Ofrecemos y transmitimos autenticidad, confianza, seguridad,
                    diseño, confort y durabilidad en todos nuestros productos
                    con hechos. Siempre analizamos los productos para ver en qué
                    pueden ser mejorados y con su implantación alcanzar
                    resultados positivos. Todo ello es valor añadido al producto
                    siendo una consideración reconocida por el usuario superior
                    al precio.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item wow fadeInUpQuick animated"
                  data-wow-delay=".8s"
                >
                  <div className="icon-wrapper">
                    <img src={EXPERIENCE_ICON} alt="EXPERIENCIA"></img>
                  </div>
                  <h2>POR EXPERIENCIA</h2>
                  <p className="text-justify">
                    Somos una empresa de primera línea con años de experiencia;
                    nos han permitido unir lo antiguo, lo nuevo y los avances
                    diarios en tecnología. En MTECHNOLOGY; es crear productos
                    útiles en el tiempo y en la función asignada.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item wow fadeInUpQuick animated"
                  data-wow-delay="1.1s"
                >
                  <div className="icon-wrapper">
                    <img src={ATENTION_ICON} alt="POR ATENCION"></img>
                  </div>
                  <h2>POR ATENCIÓN</h2>
                  <p className="text-justify">
                    Nos apasiona ofrecer la mejor atención personalizada a
                    nuestros clientes en todo tipo de consultas.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item  wow fadeInUpQuick animated"
                  data-wow-delay="1.4s"
                >
                  <div className="icon-wrapper">
                    <img src={PERSON_ICON} alt="PERSONAL EXPERTO"></img>
                  </div>
                  <h2>PERSONAL EXPERTO</h2>
                  <p className="text-justify">
                    Nuestro equipo de tecnología se compone de personal experto
                    en desarrollos de programas. Se crea el diseño acorde a tus
                    necesidades.
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </section> */}

         <section id="other-services" className="section">
          <div className="container">
            <Row>
              <div className="col-md-12 col-sm-12 text-center mb-3">
                <h1 className="wow fadeInUpQuick">NUESTROS SERVICIOS</h1>
              </div>
              <div className="col-md-4 col-sm-12 text-left">
                <h3 className="text-center">DESARROLLO WEB</h3>
                <p className="section-subcontent">
                  Desarrollo de sistemas y aplicaciones web, asi como tambien
                  páginas web personales o empresariales.
                </p>
                <img src={DESARROLLO_WEB} alt=""></img>
              </div>
              <div
                className="col-md-4 col-sm-12 text-left"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <h3 className="text-center">APPS MOVILES</h3>
                <p>
                  Desarrollo de aplicaciones móviles para <i>android</i>{" "}
                  haciendo uso del <i>Frameworks</i> mas actuales
                </p>
                <img src={DESARROLLO_APPS} alt=""></img>
              </div>

              <div className="col-md-4 col-sm-12 text-left">
                <h3 className="text-center">SERVICIO TÉCNICO</h3>
                <p>
                  Servicios de reparación de ordenadores de escritorio,
                  ordenadores portatiles y redes de comunicaciones.
                </p>
                <img src={UNAMED_ICON} alt=""></img>
              </div>
              <div className="col-md-4 col-sm-12 text-left">
                <h3 className="text-center">DEPARTAMENTO DE VENTAS</h3>
                <p>
                  Ventas de laptops, ordenadores de escritorio, pantallas,
                  monitores,tablets, sistemas de seguridad y telefonos
                  celulares.
                </p>

                <img src={DEPARTAMENTO_VENTAS} alt=""></img>
              </div>
            </Row>
          </div>
        </section> 

         <section id="promo" className="section">
          <div className="container">
            <Row>
            <Col xs={12}>
            <img src={PROMO} className="w-100" alt=""></img>
            </Col>
            </Row>
            </div>
            </section> 
        <section id="featured" className="section">
          <div className="container">
            <Row>
              <div className="col-sm-12 col-md-12 text-center mb-3">
                <h1 className=" wow fadeInUpQuick">TECNOLOGÍAS</h1>

                <p className="section-subcontent">
                  En el mundo del desarrollo, cada día nos bombardean con
                  tecnologías que aportan nuevas oportunidades y funcionalidades
                  a los proyectos de software. Como no puede ser de otra forma,
                  en <b>MTECHNOLOGY</b> apostamos por las tecnologías más
                  punteras del mercado y siempre estamos dispuestos a ser los
                  primeros en lanzarnos a probar nuevas herramientas, donde se
                  prometan mayores funcionalidades, desarrollo ágil, buenas
                  prácticas de desarrollo, mejora en rendimiento, etc.
                </p>
              </div>
              <div
                className="col-md-4 col-sm-12  min-h"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src={REACT_ICON} alt="React"></img>
                  </div>
                  <div className="featured-content">
                    <h4>REACT</h4>
                    <p>
                      Esta librería ha sido diseñada por el equipo de Facebook y
                      está pegando fuerte en el mundillo del frontend, siendo el
                      preferido por la comunidad de desarrolladores. En{" "}
                      <b>MTECHNOLOGY</b> hemos adoptado este software en muchos
                      de los últimos proyectos y ha tenido resultados excelentes
                      de usabilidad e interacción. La filosofía de React es
                      dividir la aplicación en componentes que sean fácilmente
                      reusables en diferentes partes del proyecto, compartiendo
                      información entre ellos a través de unos estados y
                      propiedades. El mayor potencial que vemos en la librería
                      React es que una vez que la aprendes, hay herramientas que
                      te ofrecen trasladar tu código a móvil y a escritorio, con
                      lo que, aprendiendo un único software puedes programar en
                      multiplataforma.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-md-4 col-sm-12  min-h"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src={DATABASE_ICON} alt="Base de datos"></img>
                  </div>
                  <div className="featured-content">
                    <h4>BASE DE DATOS</h4>
                    <ul className="pl-20">
                      <li>Mysql</li>
                      <li>Sql Server</li>
                      <li>SqlLite</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12  min-h"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src={CODEIGNITHER_ICON} alt="Codeigniter"></img>
                  </div>
                  <div className="featured-content">
                    <h4>Codeigniter</h4>
                    <p>
                      Este framework hace que el odiado lenguaje de programación{" "}
                      <b>PHP</b> vuelva a ser entretenido a la hora de
                      programar. Codeigniter ofrece soluciones directas y
                      sencillas a complejas funcionalidades en una aplicación,
                      como podían ser el modelado de datos, realización de
                      tareas periódicas, adaptación al modelo MVC, entre muchas
                      otras. A todo esto, se le suma una documentación muy
                      trabajada y una comunidad de desarrolladores cada vez más
                      extensa. Además, cabe destacar, que con la reciente
                      versión de PHP 7.0, se ha duplicado el rendimiento de las
                      aplicaciones escritas en este lenguaje de programación.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12  min-h2"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src={BOOSTRAP_ICON} alt="Bootstrap"></img>
                  </div>
                  <div className="featured-content">
                    <h4>HOJAS DE ESTILOS</h4>
                    <ul className="pl-20">
                      <li>Bootstrap v4</li>
                      <li>CSS3</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12  min-h2"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src={ORACLE_ICON} alt="Bootstrap"></img>
                  </div>
                  <div className="featured-content">
                    <h4>ADMINISTRACION DE BASE DE DATOS</h4>
                    <ul className="pl-20">
                      <li>Oracle cloud</li>
                      <li>Amazon web service</li>
                      <li>Aplicación de parche</li>
                      <li>Respaldos export Rman</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="col-md-4 col-sm-12  min-h2"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src={JAVASCRIPT_ICON} alt="JS"></img>
                  </div>
                  <div className="featured-content">
                    <h4>LENGUAJES DE PROGRAMACION</h4>
                    <ul className="pl-20">
                      <li>Java</li>
                      <li>JavaScript</li>
                      <li>C</li>
                      <li>C#</li>
                      <li>PHP</li>
                      <li>Python</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-sm-12  min-h2"
                data-animation="fadeIn"
                data-animation-delay="01"
              >
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src={CORONA_SDK_ICON} alt="Corona SDK"></img>
                  </div>
                  <div className="featured-content">
                    <h4>CORONA SDK</h4>
                    <p>
                      <b>Corona SDK</b>es una herramienta para desarrollar
                      aplicaciones y ejecutarlas en iOS, Android, Amazon Kindle
                      Fire y Burnes&Noble Nook. El tipo de aplicaciones al que
                      va destinado principalmente es a juegos, aunque también
                      permite otras funcionalidades que podrían emplearse en
                      otros ámbitos. El lenguaje que utiliza es Lua, un lenguaje
                      de scripting utilizado precisamente para desarrollar
                      juegos.
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </section> 

         <section id="team" className="section">
          <div className="container">
            <Row>
              <div className="col-sm-12 col-md-12 text-center">
                <h1 className=" wow fadeInDown" data-wow-delay=".5s">
                  REPARACIÓN DE COMPUTADORAS, IMPRESORAS, ARMADOS GAMERS,SISTEMAS DE FACTURACION ELECTRONICA
                </h1>
              </div>
              <div className="col-sm-12 col-md-12  text-center">
                <p className="section-subcontent text-center">
                 Servicio de reparación de impresoras y computadoras.
                 
                </p>
                <p className="section-subcontent text-center">
                Armados gamers, laptops de poco uso, nuevas, cpus y monitores.
                 
                </p>
              
                {/* <div className="team-item wow fadeInUpQuick" data-wow-delay="1s">
                  <figure className="team-profile">
                    <img src={OUR_TEAM_ICON} alt=""></img>
                    <figcaption className="our-team">
                      <div className="details">
                        <div className="orange-line"></div>
                        <div className="social">
                          <a
                            className="instagram"
                            href="https://instagram.com/19diegomartinez93?igshid=10yumeh6gh8sa"
                          >
                            <i className="fa fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                  <div className="info">
                    <h2>Diego Martinez</h2>
                    <div className="orange-line"></div>
                    <p>Founder And ceo</p>
                  </div>
                </div> */}
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R1} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R2} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R3} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R4} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R5} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R6} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R7} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R8} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R9} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              {/* <img src={R10} alt="" className="pic-reparacion"></img> */}
              <video width="100%" height="300" controls >
              <source src={R10} type="video/mp4" className="pic-reparacion"/>
              </video>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R11} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R12} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R13} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R14} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R15} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R16} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R17} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <img src={R18} alt="" className="pic-reparacion"></img>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <video width="100%" height="300" controls >
              <source src={R19} type="video/mp4" className="pic-reparacion"/>
              </video>
              </div>
              <div className="col-sm-6 col-md-4 text-center">
              <video width="100%" height="300" controls >
              <source src={R20} type="video/mp4" className="pic-reparacion"/>
              </video>
              </div>
            </Row>
          </div>
        </section> 

        <section id="clients" className="section">
          <div className="container">
            <Row>
              <div className="col-sm-12 col-md-12 text-center">
                <h1 className=" wow fadeInUpQuick" data-wow-delay=".5s">
                  CLIENTES
                </h1>
              </div>
              <div className="col-sm-12 col-md-12 text-center">
                <div className="wow fadeInUpQuick" data-wow-delay=".9s">
                  <Row id="clients-scrorller" className="">
                    <div className="client-item-wrapper mt-2 height_200 col-sm-6 col-md-4 col-xs-4">
                      <a
                        href="https://kerlysphotography.com/index.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={KERLYS_ICON} alt=""></img>
                      </a>
                    </div>
                    <div className="client-item-wrapper mt-2 height_200 col-sm-6 col-md-4 col-xs-4">
                      <img src={LAB_GUADALUPE} alt=""></img>
                    </div>
                    <div className="client-item-wrapper height_200 mt-2 col-sm-6 col-md-4 col-xs-4">
                      <img src={MAX_PERFORMANCE} alt=""></img>
                    </div>
                    <div className="client-item-wrapper mt-2 height_200 col-sm-6 col-md-4 col-xs-4">
                  <a href="https://kingsschooldavid.com" target="_blank" rel="noopener noreferrer">
                    <img src={KINGS} alt=""></img>
                  </a>
                </div>
                <div className="client-item-wrapper mt-2 height_200 col-sm-6 col-md-4 col-xs-4">
                  <a href="https://opabogado.com" target="_blank" rel="noopener noreferrer">
                    <img src={OPABOGADO} alt=""></img>
                  </a>
                </div>
                    <div className="client-item-wrapper mt-2 height_200 col-sm-6 col-md-4 col-xs-4">
                      <a href="http://www.apservices507.com/" target="_blank" rel="noopener noreferrer">
                        <img src={AP_BOX} alt=""></img>
                      </a>
                    </div>
                    <div className="client-item-wrapper mt-2 height_200 col-sm-6 col-md-4 col-xs-4">
                      <img src={LEOTECH} alt=""></img>
                    </div>
                    <div className="client-item-wrapper mt-2 height_200 col-sm-6 col-md-4 col-xs-4"> 
                    <a href="https://geovanycaballero.com/index.html" target="_blank" rel="noopener noreferrer">
                      <img src={GEOVANY_CABALLERO} alt=""></img>
                      </a>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          </div>
        </section>
        <section id="maps" className="section">
          <div className="container">
            <Row>
              <div className="col-sm-12 col-md-12 text-center">
                <h1 className=" wow fadeInDown" data-wow-delay=".5s">
                  UBICACIÓN
                </h1>
                <Iframe
                  url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3946.752870310519!2d-82.43034176644161!3d8.425913230126268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9df9ed18b43b198d!2sMTECHNOLOGY!5e0!3m2!1ses!2spa!4v1619967890196!5m2!1ses!2spa"
                  width="100%"
                  height="600px"
                  id="myId"
                  className="myClassname"
                  display="initial"
                  position="relative"
                />
              </div>
              <div className="col-sm-12 col-md-12 text-left mt-3">
                <p className="">
                  Estamos ubicados en David Chiriqui, Vía rápida, despues de la
                  UDI, Plaza Mallorca a lado de Megamix.
                </p>
              </div>
            </Row>
          </div>
        </section> 
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
