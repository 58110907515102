import React from "react";
import { FileManager, FileUploader } from "reactjs-file-uploader";
import { CHECK } from "./Index";
import { Label, Input, Row, Col, Button } from "reactstrap";
import { urlFiles } from "./Services";

class VanillaExample extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      nameImg: props.nameImg,
      IMG_AVATAR:props.IMG_AVATAR,
      urlHttp:props.urlHttp,
      nameFile:""
    };
    this.uploadFiles = this.uploadFiles.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  render() {
    const props = this.state;
    return (
      <React.Fragment>
        <Row>
          {props.files.length === 0 ? (
            <React.Fragment>
          <Col xs={12} className="text-center mt-2 mb-3">
          <img src={urlFiles + "/avatars/" +props.IMG_AVATAR} className="img-avatar" alt=""></img>
          </Col>
              <Col sm={1} className="text-center"></Col>
              <Col sm={10}>
                <Input
                  type="file"
                  className="m-auto"
                  accept="image/x-png, image/jpg"
                  /* tslint:disable-next-line */
                  // onChange={(event) => this.setState({ files: props.files.concat(Array.from(event.target.files)) })}
                  onChange={(event) => {

                    if (event.target.files[0].name.includes(".jpg") ) {
                       this.setState({ files: props.files.concat(Array.from(event.target.files)), nameFile:this.state.nameImg+".jpg"})
                    } else {
                      this.setState({ files: props.files.concat(Array.from(event.target.files)), nameFile:this.state.nameImg+".png" })}
                    }
                  }
                />
              </Col>
              <Col sm={1} className="text-center">
                {" "}
              </Col>
            </React.Fragment>
          ) : null}

          <Col sm={12} >
            <FileManager files={props.files}>{this.uploadFiles}</FileManager>
          </Col>
          <Col sm={12} className="text-center mt-3">
            {props.files.length === 0 ? <Label className="l-color-danger">Formatos de imagen permitido: .png .jpg .jpeg</Label> : ""}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  uploadFiles(files) {
    return files.map(this.uploadFile);
  }

  uploadFile(file) {
    return (
      <FileUploader
        key={file.key}
        file={file}
        url={this.state.urlHttp}
        formData={{
          file,
          upload_preset: "public",
          tags: "vanilla",
          nameImg: this.state.nameImg,
          nameFile: this.state.nameFile,
        }}
        readFile
      >
        {this.fileProgress}
      </FileUploader>
    );
  }

  fileProgress({
    uploadReady,
    uploadStart,
    uploadProgress,
    uploadComplete,
    downloadStart,
    downloadProgress,
    downloadComplete,
    error,
    abort,
    timeout,
    requestState,
    startUpload,
    abortRequest,
    request,
    response,
    fileData
  }) {
    return (
      <Row>
        <Col sm={12} className="text-center">
          {fileData && <img src={fileData} width={200} alt="Preview" className="img-avatar" />}
        </Col>
        <Col sm={12} className="text-center mt-4">
          {startUpload && (
            <Button className="info" onClick={startUpload}>
              Guardar Imagen
            </Button>
          )}
        </Col>
        <Col sm={12} className="save-upload-img text-center">
          {response && (
            <React.Fragment>

                <Label>Guardado</Label>
              <img className="img-status img-search" src={CHECK} alt="" />

            </React.Fragment>
          )}
        </Col>
      </Row>
    );
  }
}
export default VanillaExample;
