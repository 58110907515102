import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import StoreContext from "./../../Store/Context.js";

const RoutesPrivate = ({ component: Component, ...rest }) => {
  const { token} = useContext(StoreContext);
  if (token === undefined || token === null || !token) {
    return <Redirect to={"/home/info"} />;
  } else {
    if (!rest.path.includes("account") ) {
      return <Redirect to={"/home/info"} />;
    }
    else{
      return <Route {...rest} render={() => token !== null && <Component {...rest} />} />;
    }
    }
  
};

export { RoutesPrivate };
