import React, { Component } from "react";
import { HomeimageSlider } from "./../constant/Util";
import { Row, Col } from "reactstrap";
import StoreContext from "./../../Store/Context";
import { url, postRegisterUser, validateUser,USR_SUC} from "./../constant/Services";
import moment from "moment";
import History from "./../constant/History";
import { LOGIN, OkModal } from "./../constant/Modal";
// import Footer from "./../Menu/Footer";
import {
  PERSON_ICON,
  ATENTION_ICON,
  EXPERIENCE_ICON,
  QUALITY_ICON,
  PEOPLE,
  EMAIL,
  APPS_ICON,
  AVION_ICON,
  SEARCH
} from "../constant/Index.js";
import Button from "reactstrap/lib/Button";

class Home extends Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      children: props.children,
      loginOpen: false,
      isCollapse: false,
      okModal: false,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      isRegisterOpen:true,
      msg_error: "",
    };

    this.handleModalLogin = this.handleModalLogin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.login = this.login.bind(this);
    this.onChangeFLogin = this.onChangeFLogin.bind(this);
    this.setError = this.setError.bind(this);
    this.handlerRegister = this.handlerRegister.bind(this);
    this.toggleSaveRegister = this.toggleSaveRegister.bind(this);
    this.seTypeSex = this.seTypeSex.bind(this);
    this.fetchValidateUser = this.fetchValidateUser.bind(this);
    this.handlerOkModal = this.handlerOkModal.bind(this);
  }
  async login({ user, password }) {
    if (user.length > 0 && password.length > 0) {
      const dataBody = {
        user,
        password,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(dataBody),
      };
      const response = await fetch(
        url + "LoginController/login",
        requestOptions
      );
  
      const result = await response.json();

      if (result[0].count !== "0") {
        return {
          token: true,
          usr_id: result[0].usr_id,
          usr_name: result[0].usr_name,
          usr_type: result[0].usr_type,
        };
      } else {
        return { error: "Usuario o contraseña incorrectos" };
      }
    } else {
      return { error: "Campos estan vacios" };
    }
  }

  onChangeFLogin(e) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }
  async onSubmit(event) {
    event.preventDefault();
    const props = this.state.dataFLogin;
    const { token, usr_id, usr_name, usr_type, error } = await this.login({
      user: props.celphone,
      password: props.password,
    });

    if (token) {
      this.context.quitToken();
      this.context.setToken({ token, usr_id, usr_name, usr_type });
      if (usr_type === "2") {
        return History.push("/admin");
      } else {
        this.setState({ isRegisterOpen: false, loginOpen: false });
        return History.push("/profile/account");
      }
    }
    this.setError(error);
  }
  handlerOkModal() {
    this.setState((prevState) => ({
      okModal: !prevState.okModal,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    }));
  }

  handleModalLogin() {
    this.setState((prevState) => ({
      loginOpen: !prevState.loginOpen,
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
      isCollapse: false,
    }));
  }
  setError(error) {
    this.setState({
      msg_error: error,
    });
  }
  componentDidMount() {
    this.context.quitToken();
  }
  async fetchValidateUser(usr, pass) {
    const props = this.state.dataFLogin;
    const response = await validateUser(usr, pass);
    if (response.exist) {
      this.setState({
        msg_error: "Tenemos una cuenta registrada con ese numero de teléfono",
      });
    } else {
      var usr_code_ =
        response.set_siglas[0].set_siglas +
        this.paddy(response.n_box[0].n_box, 5);
      const body = {
        usr_code: usr_code_,
        usr_box_pr:
          usr_code_ +
          " " +
          props.usr_name.toUpperCase() +
          "\n" +
          response.set_address[0].set_address,
        usr_price: response.set_price_libra[0].set_price_libra,
        usr_name: props.usr_name.toUpperCase(),
        usr_user: props.usr_celphone,
        usr_celphone: props.usr_celphone,
        usr_password: props.usr_password1,
        usr_email: props.usr_email,
        usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
        usr_sex: props.usr_sex,
        usr_suc:USR_SUC
      };

      postRegisterUser(body);
      this.setState({
        isRegisterOpen: false,
        loginOpen: false,
        okModal: true,
      });
    }
  }

  paddy(num, padlen, padchar) {
    var pad_char = typeof padchar !== "undefined" ? padchar : "0";
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  toggleSaveRegister() {
    const props = this.state.dataFLogin;
    if (
      props.usr_name.length > 3 &&
      props.usr_email.length > 0 &&
      props.usr_birthday.length > 0 &&
      props.usr_celphone.length > 0 &&
      props.usr_password1.length > 0 &&
      props.usr_password2.length > 0
    ) {
      if (props.usr_password1 === props.usr_password2) {
        this.fetchValidateUser(props.usr_celphone, props.usr_password1);
      } else {
        this.setState({
          msg_error: "Contraseñas diferentes",
        });
      }
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  handlerRegister() {
    this.setState({
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    });
  }

  seTypeSex({ usr_sex }) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        usr_sex,
      },
    });
  }
  render() {
    const props = this.state;
    return (
      <React.Fragment>
        {props.okModal && (
          <OkModal
            isOpen={props.okModal}
            toggle={this.handlerOkModal}
            usr_name={props.dataFLogin.usr_name}
          />
        )}
        {props.loginOpen && (
          <LOGIN
            onChange={this.onChangeFLogin}
            isOpen={props.loginOpen}
            toggle={this.handleModalLogin}
            toggleSave={this.onSubmit}
            toggleSaveRegister={this.toggleSaveRegister}
            dataFTeacher={props.dataFLogin}
            msg_error={props.msg_error}
            isRegisterOpen={props.isRegisterOpen}
            handlerRegister={this.handlerRegister}
            seTypeSex={this.seTypeSex}
          />
        )}

        <div className="container mt-4">
          <div className="text-center">
            <div className="error-page">
              <Button color="success" onClick={this.handleModalLogin}>Registrarme</Button>{" "}
            </div>
          </div>
        </div>
        <div className=" mt-3 mb-3 text-center">
          {/* <img src={POBOX} className="img-pobox" alt="POPBOX"></img>

            <img src={AVION} className="img-pobox mt-2" alt="POPBOX"></img> */}

          <HomeimageSlider />
        </div>
        <section id="service-block-main" className="section">
          <div className="container">
            <Row>
              <div className="col-sm-12 col-md-12 mb-3 text-center">
                <h1 className=" wow fadeIn animated mb-3" data-wow-delay=".2s">
                  COMPRAS POR INTERNET
                </h1>
                <p className="text-justify">
                  <strong>
                    <b>CASILLERO (P.O. BOX) EN MIAMI</b>
                  </strong>{" "}
                  <br></br>
                  Si Tienes tarjeta de crédito y deseas realizar tus compras?
                  ¡No hay problema! Envía tus productos a tu casillero en Miami
                  y nosotros nos encargamos del resto. No cobramos peso
                  volumétrico. Contacta con nosotros y adquiere tu casillero
                  totalmente gratis. <br></br>
                  ¡Pero! ¿no tienes tarjeta de crédito? No te preocupes,
                  nosotros hacemos la compra por ti y te asesoramos en tus
                  compras.
                </p>
              </div>
            </Row>
            <Row>
              <Col xs={12}></Col>
              <Col xs={6} className="mt-1">
                Carga <b>Aérea</b>
                <img alt="" src={AVION_ICON} className="icon-compras"></img>
                <br></br>
                <p>
                  Este servicio facilita el transporte de todo tipo de paquetes
                  y mercancías, desde 1 libra hasta envíos con peso elevado.
                </p>
              </Col>
              <Col xs={6} className="mt-1">
                <b>Corporativos</b>
                <img alt="" src={PEOPLE} className="icon-compras"></img>
                <br></br>
                <p>
                  Planes diseñados para empresas con soluciones logísticas,
                  tarifas especiales, trámites aduanales y transporte local.
                </p>
              </Col>
              <Col xs={6} className="mt-4">
                <b>Rastreo</b>
                <img alt="" src={SEARCH} className="icon-compras"></img>
                <br></br>
                <p>
                  Ingresando a tu cuenta podrás darle seguimiento a tus
                  paquetes.
                </p>
              </Col>
              <Col xs={6} className="mt-4">
                <b>Notificaciones</b>
                <img alt="" src={EMAIL} className="icon-compras"></img>
                <br></br>
                <p>
                  Enviamos correos cuando tu carga esta lista para ser retirada.
                </p>
              </Col>
              <Col xs={6} className="mt-4">
                APP <b>Clientes</b>
                <img alt="" src={APPS_ICON} className="icon-compras"></img>
                <br></br>
                <p>
                  Hemos creado un sistema online en donde podrás ver estado de
                  tus paquetes, revisar facturas, cotizar y más...
                </p>
              </Col>
              <Col xs={6} className="mt-4"></Col>
            </Row>
            <Row>
              <div className="col-sm-12 col-md-12 text-center mt-5">
                <h1 className=" wow fadeIn animated" data-wow-delay=".2s">
                  ¿POR QUÉ ELEGIRNOS?
                </h1>
              </div>
              <div className="col-sm-12 col-md-12">
                <ul className="vineta mt-5">
                  <li>
                    <p className="section-subcontent">
                      Por nuestra efectividad, aseguramos resultados;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque realizamos proyectos personalizados a cada cliente;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque aseguramos el cumplimiento de los plazos
                      establecidos;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque tratamos a cada cliente como si fuera el único;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque su empresa y su proyecto son importantes para
                      nosotros;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque nos implicamos al cien por cien en cada proyecto;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque somos flexibles, nos adaptamos a las necesidades de
                      su empresa;
                    </p>
                  </li>
                  <li>
                    <p className="section-subcontent">
                      Porque trabajamos colaborativamente con nuestros clientes.
                      ¡Eres parte de nuestra empresa!
                    </p>
                  </li>
                </ul>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item wow fadeInUpQuick animated"
                  data-wow-delay=".5s"
                >
                  <div className="icon-wrapper">
                    <img src={QUALITY_ICON} alt="Quality"></img>
                  </div>
                  <h2>POR CALIDAD</h2>
                  <p className="text-justify">
                    Ofrecemos y transmitimos autenticidad, confianza, seguridad,
                    diseño, confort y durabilidad en todos nuestros productos
                    con hechos. Siempre analizamos los productos para ver en qué
                    pueden ser mejorados y con su implantación alcanzar
                    resultados positivos. Todo ello es valor añadido al producto
                    siendo una consideración reconocida por el usuario superior
                    al precio.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item wow fadeInUpQuick animated"
                  data-wow-delay=".8s"
                >
                  <div className="icon-wrapper">
                    <img src={EXPERIENCE_ICON} alt="EXPERIENCIA"></img>
                  </div>
                  <h2>POR EXPERIENCIA</h2>
                  <p className="text-justify">
                    Somos una empresa de primera línea con años de experiencia;
                    nos han permitido unir lo antiguo, lo nuevo y los avances
                    diarios en tecnología. En MTECHNOLOGY; es crear productos
                    útiles en el tiempo y en la función asignada.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item wow fadeInUpQuick animated"
                  data-wow-delay="1.1s"
                >
                  <div className="icon-wrapper">
                    <img src={ATENTION_ICON} alt="POR ATENCION"></img>
                  </div>
                  <h2>POR ATENCIÓN</h2>
                  <p className="text-justify">
                    Nos apasiona ofrecer la mejor atención personalizada a
                    nuestros clientes en todo tipo de consultas.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div
                  className="service-item  wow fadeInUpQuick animated"
                  data-wow-delay="1.4s"
                >
                  <div className="icon-wrapper">
                    <img src={PERSON_ICON} alt="PERSONAL EXPERTO"></img>
                  </div>
                  <h2>PERSONAL EXPERTO</h2>
                  <p className="text-justify">
                    Nuestro equipo de tecnología se compone de personal experto
                    en desarrollos de programas. Se crea el diseño acorde a tus
                    necesidades.
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </section>
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}
export default Home;