import React, { useState, useCallback } from "react";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { reactLocalStorage } from "reactjs-localstorage";
import ImageViewer from "react-simple-image-viewer";
import { nonce, path, privateKey, urlMultimedia } from "./Services";
import { POBOX, AVION } from "./Index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const HomeimageSlider = () => {
  return (
    <Carousel autoPlay={true} autoFocus={true} dynamicHeight={true} infiniteLoop={true} interval={5000}>
      <div>
        <img src={POBOX} alt=""/>
      </div>
      <div>
        <img src={AVION} alt="" />
      </div>
    </Carousel>
  );
};

const validateTokenStorage = () => {
  var x = reactLocalStorage.getObject("tokenP");
  const hashDigest = sha256(nonce + x.usr_type + x.usr_id);
  const hmacDigest = Base64.stringify(
    hmacSHA512(path + hashDigest, privateKey)
  );
  if (x.key !== undefined) {
    if (x.key !== hmacDigest) {
      return true;
    }
  }
  return false;
};

const ImageViewerAnnouncement = ({ ad_img, src_ }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [urlMultimedia + ad_img];
  const images2 = [];
  if (ad_img === "track") {
    images2.push(urlMultimedia + "trac1.png");
    images2.push(urlMultimedia + "trac2.png");
    images2.push(urlMultimedia + "trac3.png");
    images2.push(urlMultimedia + "trac14.png");
  } else if (ad_img === "shop") {
    images2.push(urlMultimedia + "shop1.png");
    images2.push(urlMultimedia + "shop2.png");
  } else if (ad_img === "stock") {
    images2.push(urlMultimedia + "stock1.png");
    images2.push(urlMultimedia + "stock2.png");
    images2.push(urlMultimedia + "stock3.png");
    images2.push(urlMultimedia + "stock4.png");
  } else if (ad_img === "pos") {
    images2.push(urlMultimedia + "pos1.jpg");
  }

  const openImageViewer = useCallback((index, ad_img) => {
    if (ad_img !== "default") {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="img-viewer">
      {images.map((src, index) => (
        <img
          src={src_}
          onClick={() => openImageViewer(index, ad_img)}
          className="img-upload-2 img-portafolio"
          key={Date.now()}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images2}
          currentIndex={currentImage}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
};

export { HomeimageSlider, validateTokenStorage, ImageViewerAnnouncement };
