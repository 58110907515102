/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import moment from "moment";
import {  Row, Col } from "reactstrap";
import { DESARROLLO_APPS, TEAM_ICON, FEATURE_ICON, UNAMED_ICON } from "../constant/Index.js";
const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="container">
          <Row className="section">
            <div className="footer-widget col-md-3 col-xs-12 wow fadeIn">
              <h3 className="small-title">SOBRE NOSOTROS</h3>
              <p>
                Somos una empresa de primera línea, con años de experiencia; nos han permitido
                unir lo antiguo, lo nuevo y los avances diarios en tecnología.
              </p>
              <p>
                En MTECHNOLOGY; se crea productos útiles en el tiempo y en la
                función asignada.
              </p>
              <div className="social-footer">
                <a href="https://www.facebook.com/mtechnology.panama.1" target="_blank">
                  <i className="fa fa-facebook icon-round"></i>
                </a>
                <a href="https://www.linkedin.com/in/diego-martinez-b1b0ba129" target="_blank">
                  <i className="fa fa-linkedin icon-round"></i>
                </a>
                <a href="https://www.instagram.com/m_technology507/" target="_blank">
                  <i className="fa fa-instagram icon-round"></i>
                </a>
              </div>
            </div>
            <div className="footer-widget col-md-3 col-xs-12 wow fadeIn" data-wow-delay=".2s">
              <h3 className="small-title">CONTÁCTENOS</h3>
              <ul>
                <li>mtechnologypanama@gmail.com</li>
                <li>diego_martinez93@live.com</li>
                <li>+50765041394</li>
              </ul>
            </div>
            <div className="footer-widget col-md-3 col-xs-12 wow fadeIn" data-wow-delay=".5s">
              <h3 className="small-title">NUESTROS SERVICIOS</h3>
              <div className="plain-flicker-gallery">
                <a>
                  <img src={DESARROLLO_APPS} alt=""></img>
                </a>
                <a >
                  <img src={TEAM_ICON} alt=""></img>
                </a>
                <a>
                  <img src={FEATURE_ICON} alt=""></img>
                </a>
                <a >
                  <img src={UNAMED_ICON} alt=""></img>
                </a>
              </div>
            </div>
          </Row>
        </div>

        <div id="copyright">
          <div className="container">
            <Row>
              <Col sm={12} className="text-center">
                <p className="copyright-text">©{moment(new Date()).format("YYYY")} MTECHNOLOGY. All right reserved.</p>
              </Col>
            </Row>
          </div>
        </div>
      </footer>

      <a href="#dd" className="back-to-top">
        <i className="fa fa-angle-up"></i>
      </a>

      <div className="bottom">
        {" "}
        <a href="#d" className="settings">
          {" "}
        </a>{" "}
      </div>
    </React.Fragment>
  );
};
export default Footer;
