import React, { Component } from "react";
// import {Button,Row, Col } from "reactstrap";
import Footer from "./../Menu/Footer";
class Contact extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header-section">
          <div className="container">
            <div className="row">
              <div className="page-header-area">
                <div className="page-header-content">
                  <h2>Contáctenos</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="contact" className="row mt-5 mb-5 mr-u">
          <div className="col-sm-1 col-xs-1"></div>
          <div className="col-sm-12 col-xs-12">
            <ul>
              <li>mtechnologypanama@gmail.com</li>
              <li>65041394</li>
            </ul>
          </div>
          {/* <div className="col-sm-5 col-xs-11 padding float-right">
            <h3 className="small-title">ESCRÍBENOS</h3>
            <div className="contact-us">
              <p>Envíenos un mensaje.</p>
              <form className="text-center">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName2"
                    placeholder="Nombre completo"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail2"
                    placeholder="Email"
                  />
                </div>
                <button type="submit" className="btn btn-common">
                  Enviar
                </button>
              </form>
            </div>
          </div> */}
          <div className="col-sm-1 col-xs-1"></div>
        </div>
        <Footer/>
      </React.Fragment>
    );
  }
}

export default Contact;
