import React, { Component } from "react";
import "./../../assets/css/main.css";
import Footer from "./../Menu/Footer";
import Iframe from 'react-iframe';
class Sales extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header-section">
          <div className="container">
            <div className="row">
              <div className="page-header-area">
                <div className="page-header-content text-center">
                  <h2>Instagram</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 mt-unset-xs mb-5 p-5p ">
          <div className="row">
            <div className="col-sm-12 col-md-12 text-center mt-3">
              <script src="https://snapwidget.com/js/snapwidget.js"></script>
              <Iframe url="https://snapwidget.com/embed/933958"
                width="100%"
                height="900px"
                id="myId3"
                className="snapwidget-widget"
                display="initial"
                overflow ="hidden"
                allowFullScreen={true}
                position="relative"/>
            </div>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
    );
  }
}

export default Sales;
