import React, {Component } from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';
import StoreContext from './Context';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import {validateTokenStorage } from "../components/constant/Util";

import{nonce,path,privateKey} from './../components/constant/Services';

var x = reactLocalStorage.getObject('tokenP');
if(validateTokenStorage()){
  reactLocalStorage.clear();
}


class StoreProvider extends Component{
  constructor(props) {
    super(props);
    this.state = {
      token:x.token,
      usr_id:x.usr_id,
      usr_name: x.usr_name,
      usr_type:x.usr_type,
      children: props.children,
      key:x.key
    };

    this.setToken = this.setToken.bind(this);
    this.quitToken = this.quitToken.bind(this);
  }

  getKeyToke({usr_id,usr_type}){
    const hashKey = sha256(nonce + usr_type +usr_id);
    const hmacKey = Base64.stringify(hmacSHA512(path + hashKey, privateKey));
    return hmacKey
  }

  setToken = ({token,usr_id,usr_name,usr_type})=> {

    const keyToken = this.getKeyToke({usr_id:usr_id,usr_type:usr_type})
    this.setState({
      token,
      usr_id,
      usr_name,
      usr_type,
      key:keyToken
    });
    reactLocalStorage.setObject('tokenP',{usr_id,usr_name,usr_type,token,key:keyToken});
  }

  quitToken = ()=> {
    this.setState({
      allow:null,
      token:null,
      usr_type:null,
      per_id:null,
      year:null,
      per_admin:null,
      per_std:null,
      key:null
    });
    reactLocalStorage.clear();
  }
  
  render() {
    const props= this.state;
    return (
      <StoreContext.Provider value={{token:props.token,usr_id:props.usr_id,usr_name:props.usr_name,usr_type:props.usr_type,key:props.key,setToken:this.setToken,quitToken:this.quitToken}}>
            {props.children}
      </StoreContext.Provider>
    );
  }
}

export default StoreProvider;