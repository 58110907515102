import React from "react";
import moment from "moment";
import History from './../constant/History';
import { LOGIN } from "./../constant/Modal";
import StoreContext from "./../../Store/Context";
import {
  Col
} from "reactstrap";
import { url,postRegisterUser } from "./../constant/Services";
import "./../../assets/css/main.css";
import "./../../assets/css/main.css";

class LayoutAccount extends React.Component{
  static contextType = StoreContext
  constructor(props) {
    super(props);
    this.state = {
      children:props.children,
      loginOpen: false,
      isCollapse: false,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name:"",
        usr_email:"",
        usr_address:"",
        usr_birthday:"",
        usr_sex:"",
        usr_celphone:"",
        usr_password1:"",
        usr_password2:"",
        usr_cedula:""
      },
      msg_error: "",
      isRegisterOpen:false
    };
    this.handleModalLogin = this.handleModalLogin.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.login = this.login.bind(this);
    this.onChangeFLogin = this.onChangeFLogin.bind(this);
    this.setError = this.setError.bind(this);
    this.handlerRegister = this.handlerRegister.bind(this);
    this.toggleSaveRegister = this.toggleSaveRegister.bind(this);
  }

  toggleSaveRegister() {
    const props = this.state.dataFLogin;
    if ((props.usr_password1 = props.usr_password2)) {
     
      if (props.usr_name.length > 6 &&  props.usr_email.length > 0  &&  props.usr_address.length > 0  &&  props.usr_birthday.length > 0  &&  props.usr_celphone.length > 0 ) {
        const body = {
          usr_name: props.usr_name,
          usr_user: props.usr_celphone,
          usr_password: props.usr_password1,
          usr_email: props.usr_email,
          usr_cedula: props.usr_cedula,
          usr_address: props.usr_address,
          usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
          usr_sex:props.usr_sex
        };
        postRegisterUser(body);
      } else {
        this.setState({
          msg_error: "Campos vacios",
        });
      }
    } else {
      this.setState({
        msg_error: "Contraseñas diferentes",
      });
    }

    

  }

  handleModalLogin() {
    this.setState((prevState) => ({
      loginOpen: !prevState.loginOpen,
      dataFLogin: {
        celphone: "",
        password: "",
      },
      msg_error: "",
      isCollapse: false,
    }));
  }
  setError(error) {
    this.setState({

      msg_error: error,

    });
  }

  async login({ user, password }) {
    if (user.length > 0 && password.length > 0) {
      const dataBody = {
        user,
        password,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(dataBody),
      };
      const response = await fetch(
        url + "LoginController/login",
        requestOptions
      );
      const result = await response.json();

      if (result[0].count !=="0") {
        return {
          token: true,
          usr_id: result[0].usr_id,
          usr_name: result[0].usr_name,
          usr_type: result[0].usr_type,
        };
      } else {
        return { error: "Usuario o contraseña incorrectos" };
      }
    } else {
      return { error: "Campos estan vacios" };
    }
  }

  async onSubmit(event) {
    event.preventDefault();
    const props = this.state.dataFLogin;
    const { token, usr_id, usr_name, usr_type, error } = await this.login({
      user: props.celphone,
      password: props.password,
    });

    if (token) {
      this.context.quitToken();
      this.context.setToken({ token, usr_id, usr_name,usr_type});
      if(usr_type ==="2"){
        return History.push("/profile/account");
      }
      else{
        return  History.push("profile/account");
      }
      
    }
    this.setError(error);
  }

  toggleCollapse() {
    this.setState((prevState) => ({
      isCollapse: !prevState.isCollapse,
    }));
  }
  toggleNavbar() {

  }

  onChangeFLogin(e) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        [e.target.name]: e.target.value,
      },
    });
  }

  handlerRegister(){
    this.setState({
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name:"",
        usr_email:"",
        usr_address:"",
        usr_birthday:"",
        usr_sex:"",
        usr_celphone:"",
      },
      msg_error: "",
    });
  };

  render() {
    const props = this.state;
    return (
      <React.Fragment>
        <LOGIN
          title="login"
          onChange={this.onChangeFLogin}
          isOpen={props.loginOpen}
          toggle={this.handleModalLogin}
          toggleSave={this.onSubmit}
          toggleSaveRegister={this.toggleSaveRegister}
          dataFTeacher={props.dataFLogin}
          msg_error={props.msg_error}
          isRegisterOpen ={props.isRegisterOpen}
          handlerRegister={this.handlerRegister}
        />
  
        <Col sm={12}>{this.state.children}</Col>
      </React.Fragment>
    );
  }
}

export default LayoutAccount;