import React, { Component } from "react";
import Iframe from 'react-iframe'

class Software extends Component {
  constructor() {
    super();
    this.state = {
  }
}

  render() {

    return (
      <React.Fragment>

<Iframe url="http://mtechnologypanama.com/"
        width="100%"
        height="600px"
        id="myId"
        className="iframe-sales"
        display="initial"
        position="relative"/>
      </React.Fragment>
    );
  }
}

export default Software;
