import React, { Component } from "react";
import StoreContext from "./../../Store/Context";
import { url, postRegisterUser, validateUser } from "./../constant/Services";
import moment from "moment";
import History from "./../constant/History";
import { LOGIN, OkModal } from "./../constant/Modal";

import "./../../assets/css/apbox.css"
import {
    IMG1,
    IMG2,IMG3,IMG4,IMG5,IMG6,IMG7,IMG8,IMG9,IMG10,IMG11,IMG12,IMG13
} from "../constant/Index.js";


class Home extends Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      children: props.children,
      loginOpen: false,
      isCollapse: false,
      okModal: false,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      isRegisterOpen:true,
      msg_error: "",
    };

    this.handleModalLogin = this.handleModalLogin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.login = this.login.bind(this);
    this.onChangeFLogin = this.onChangeFLogin.bind(this);
    this.setError = this.setError.bind(this);
    this.handlerRegister = this.handlerRegister.bind(this);
    this.toggleSaveRegister = this.toggleSaveRegister.bind(this);
    this.seTypeSex = this.seTypeSex.bind(this);
    this.fetchValidateUser = this.fetchValidateUser.bind(this);
    this.handlerOkModal = this.handlerOkModal.bind(this);
  }

  async login({ user, password }) {
    if (user.length > 0 && password.length > 0) {
      const dataBody = {
        user,
        password,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(dataBody),
      };
      const response = await fetch(
        url + "LoginController/login",
        requestOptions
      );
  
      const result = await response.json();

      if (result[0].count !== "0") {
        return {
          token: true,
          usr_id: result[0].usr_id,
          usr_name: result[0].usr_name,
          usr_type: result[0].usr_type,
        };
      } else {
        return { error: "Usuario o contraseña incorrectos" };
      }
    } else {
      return { error: "Campos estan vacios" };
    }
  }

  onChangeFLogin(e) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }
  async onSubmit(event) {
    event.preventDefault();
    const props = this.state.dataFLogin;
    const { token, usr_id, usr_name, usr_type, error } = await this.login({
      user: props.celphone,
      password: props.password,
    });

    if (token) {
      this.context.quitToken();
      this.context.setToken({ token, usr_id, usr_name, usr_type });
      if (usr_type === "2") {
        return History.push("/admin");
      } else {
        this.setState({ isRegisterOpen: false, loginOpen: false });
        return History.push("/profile/account");
      }
    }
    this.setError(error);
  }

  handlerOkModal() {
    this.setState((prevState) => ({
      okModal: !prevState.okModal,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    }));
  }

  handleModalLogin() {
    this.setState((prevState) => ({
      loginOpen: !prevState.loginOpen,
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
      isCollapse: false,
    }));
  }
  setError(error) {
    this.setState({
      msg_error: error,
    });
  }
  componentDidMount() {
    this.context.quitToken();
  }
  async fetchValidateUser(usr, pass) {
    const props = this.state.dataFLogin;
    const response = await validateUser(usr, pass);
    if (response.exist) {
      this.setState({
        msg_error: "Tenemos una cuenta registrada con ese numero de teléfono",
      });
    } else {
      var usr_code_ =
        response.set_siglas[0].set_siglas +
        this.paddy(response.n_box[0].n_box, 5);
      const body = {
        usr_code: usr_code_,
        usr_box_pr:
          usr_code_ +
          " " +
          props.usr_name.toUpperCase() +
          "\n" +
          response.set_address[0].set_address,
        usr_price: response.set_price_libra[0].set_price_libra,
        usr_name: props.usr_name.toUpperCase(),
        usr_user: props.usr_celphone,
        usr_celphone: props.usr_celphone,
        usr_password: props.usr_password1,
        usr_email: props.usr_email,
        usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
        usr_sex: props.usr_sex,
      };

      postRegisterUser(body);
      this.setState({
        isRegisterOpen: false,
        loginOpen: false,
        okModal: true,
      });
    }
  }

  paddy(num, padlen, padchar) {
    var pad_char = typeof padchar !== "undefined" ? padchar : "0";
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  toggleSaveRegister() {
    const props = this.state.dataFLogin;
    if (
      props.usr_name.length > 3 &&
      props.usr_email.length > 0 &&
      props.usr_birthday.length > 0 &&
      props.usr_celphone.length > 0 &&
      props.usr_password1.length > 0 &&
      props.usr_password2.length > 0
    ) {
      if (props.usr_password1 === props.usr_password2) {
        this.fetchValidateUser(props.usr_celphone, props.usr_password1);
      } else {
        this.setState({
          msg_error: "Contraseñas diferentes",
        });
      }
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  handlerRegister() {
    this.setState({
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    });
  }

  seTypeSex({ usr_sex }) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        usr_sex,
      },
    });
  }
  render() {
    const props = this.state;
    return (
      <React.Fragment>
        {props.okModal && (
          <OkModal
            isOpen={props.okModal}
            toggle={this.handlerOkModal}
            usr_name={props.dataFLogin.usr_name}
          />
        )}
        {props.loginOpen && (
          <LOGIN
            onChange={this.onChangeFLogin}
            isOpen={props.loginOpen}
            toggle={this.handleModalLogin}
            toggleSave={this.onSubmit}
            toggleSaveRegister={this.toggleSaveRegister}
            dataFTeacher={props.dataFLogin}
            msg_error={props.msg_error}
            isRegisterOpen={props.isRegisterOpen}
            handlerRegister={this.handlerRegister}
            seTypeSex={this.seTypeSex}
          />
        )}
<html lang="en">
  <head>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <title>AP Services</title>
    <link rel="icon" type="image/x-icon" href="assets/img/favicon.ico" />


    {/* <link href="../../assets\css\apbox.css" rel="stylesheet" /> */}
  </head>
  <body id="page-top">

    <nav className="navbar navbar-expand-lg navbar-dark fixed-top d-none d-sm-block" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">AP Services</a>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i className="fas fa-bars ml-1"></i>
        </button>
        <div className="collapse navbar-collapse " id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ml-auto">
            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#services">SERVICIOS</a></li>
            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#portfolio">Portafolio</a></li>
            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">Acerca de</a></li>
            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#team">Equipo</a></li>
             <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">Contacto</a></li> 
          </ul>
        </div>
      </div>
    </nav>
   
    <div className="container">
      <div className="row">
        <div className="col-12 logo-ap row-ap-box">
  
        </div>
    </div>
    </div>
    <header className="masthead">
      <div className="container">
        <div className="masthead-subheading">Bienvenido a AP SERVICES!</div>
        <div className="masthead-heading text-uppercase">Gusto en conocerte</div>
        <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Dime mas!</a>
      </div>
    </header>

    <section className="page-section" id="services">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Servicios</h2>
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">Ventas</h4>
            <p className="text-muted">Venta de computadoras y celulares y accesorios para tus equipos tecnologicos.</p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">Reparacion de computadoras</h4>
            <p className="text-muted">Especialista en reparacion de computadoras de escritorios y laptops.</p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">Dessarrollo de software</h4>
            <p className="text-muted">Desarrollo de software WEB y MOBILE con tecnologias como REACT, REACT NATIVE SQL, C#, Bootstrap,etc..</p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-file-invoice-dollar fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">Facturas banners/tarjetas de presentacion</h4>
            <p className="text-muted">Todo en impresiones de banners y tarjetas de presentacion</p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="far fa-credit-card fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">CASILLERO (P.O. BOX) EN MIAMI</h4>
            <p className="text-muted">
              Si Tienes tarjeta de crédito y deseas realizar tus compras? ¡no hay problema! Envíalas a tu casillero en Miami y nosotros nos encargamos
              del resto.
              <br />
              no cobramos peso volumetrico. contactate con nosotros y adquiere tu casillero totalmente gratis.
              <br />

              ¡pero! ¿ sino tengo tarjeta de credito? no te preocupes nosotros hacemos la compra por ti y te asesoramos en tus compras.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section bg-light" id="portfolio">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Portafolio</h2>
     
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                </div>
                <img className="img-fluid" src={IMG10} alt="" />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">HORARIO</div>
                <div className="portfolio-caption-subheading text-muted">Horario de atencion</div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal2">
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                </div>
                <img className="img-fluid" src={IMG11} alt="" />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Ver</div>
                <div className="portfolio-caption-subheading text-muted">CARGA MIAMI PANAMA</div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal3">
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                </div>
                <img className="img-fluid" src={IMG12} alt="" />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Reparacion de computadoras</div>
                <div className="portfolio-caption-subheading text-muted">Software</div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
            <div className="portfolio-item">
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal4">
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                </div>
                <img className="img-fluid" src={IMG13} alt="" />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Costo x Libra Real</div>
                <div className="portfolio-caption-subheading text-muted">3.00 $</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section className="page-section" id="about">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Acerca de nosotros</h2>
          <h3 className="section-subheading text-muted">
            APSERVICES, es una empresa enfocada en ofrecer la mejor atención al cliente en todos nuestros servicios. Compras por internet desde carga
            marítima y aérea en Estados Unidos, Europa y Asia. Brindamos asesoría, atención personalizada: compras por internet, venta de
            computadores, equipos tecnológicos y reparación de tus equipos.
          </h3>
        </div>
      </div>
    </section>

    <section className="page-section bg-light" id="team">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Nuestro equipo</h2>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="team-member">
              <img className="mx-auto rounded-circle" src={IMG9} alt="" />
              <h4>ALEJANDRO PINILLA</h4>
              <p className="text-muted">Licenciado en redes informáticas</p>
             <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-linkedin-in"></i></a> 
            </div>
          </div>
          <div className="col-lg-4">
            <div className="team-member">
              <img className="mx-auto rounded-circle" src={IMG8}alt="" />
              <h4>GABRIELA PINILLA</h4>
              <p className="text-muted">Atención al cliente</p>
              <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-linkedin-in"></i></a> 
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 my-3">
            <img className="img-fluid d-block mx-auto width-inh" src={IMG7} alt="" />
          </div>
        </div>
      </div>
    </div>

    <footer className="footer py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 text-lg-left d-flex">Copyright © www.apservices507.com 2020</div>
          <div className="col-lg-4 my-3 my-lg-0">
            <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-twitter"></i></a> 
            <a className="btn btn-dark btn-social mx-2" href="https://instagram.com/apservice507?igshid=ydsve2eduber"><i className="fab fa-instagram"></i></a>
            <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-linkedin-in"></i></a> 
          </div>
          <div className="col-lg-4 text-lg-right d-flex">
            <a className="mtechnology" href="http://mtechnologypanama.com">Desarrollado por www.mtechnologypanama.com</a>
             <a className="btn btn-dark btn-social mx-2" href="https://www.instagram.com/m_technology507"><i className="fab fa-instagram"></i></a> 
          </div>
        </div>
      </div>
    </footer>

    <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="close-modal" data-dismiss="modal"><img src={IMG6} alt="Close modal" /></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="modal-body">

                  <h2 className="text-uppercase">HORARIO</h2>

                  <img className="img-fluid d-block mx-auto" src={IMG5} alt="" />
                  <ul className="list-inline">
                    <li>Horario Lunes a viernes 8:00AM a 5:00 PM</li>
                    <li>Sábados 8:00 Am a 2:00PM</li>
                    <li>
                      contactanos wasap 62326607 instagram @apservice507 correo apservice@criptext.com ubicacion avenida central, diagonal al
                      restaurante gloton´s
                    </li>
                  </ul>
                  <button className="btn btn-primary" data-dismiss="modal" type="button">
                    <i className="fas fa-times mr-1"></i>
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="portfolio-modal modal fade" id="portfolioModal2" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="close-modal" data-dismiss="modal"><img src={IMG4} alt="Close modal" /></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="modal-body">
                  
                  <h2 className="text-uppercase">CASILLERO (P.O. BOX) EN MIAMI</h2>
                  <img className="img-fluid d-block mx-auto" src={IMG3} alt="" />
                  <p>
                    Si Tienes tarjeta de crédito y deseas realizar tus compras? ¡no hay problema! Envíalas a tu casillero en Miami y nosotros nos
                    encargamos del resto. no cobramos peso volumetrico. contactate con nosotros y adquiere tu casillero totalmente gratis. ¡pero! ¿
                    sino tengo tarjeta de credito? no te preocupes nosotros hacemos la compra por ti y te asesoramos en tus compras.
                  </p>
                  <ul className="list-inline">
                                        <li>Date: January 2020</li>
                                        <li>Client: Explore</li>
                                        <li>Category: Graphic Design</li>
                                    </ul>
                  <button className="btn btn-primary" data-dismiss="modal" type="button">
                    <i className="fas fa-times mr-1"></i>
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="portfolio-modal modal fade" id="portfolioModal3" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="close-modal" data-dismiss="modal"><img src={IMG2} alt="Close modal" /></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="modal-body">
  
                  <h2 className="text-uppercase">SERVICIOS</h2>
                 <p className="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p>
                  <img className="img-fluid d-block mx-auto" src={IMG1} alt="" />
                 <ul className="list-inline">
                    <li>venta de computadoras y celulares y accesorios para tus equipos tecnologicos</li>
                    <li>reparacion de computadora</li>
                    <li>desarrollo de software y programacion web facturas banners/tarjetas de presentacion</li>
                  </ul>
                  <button className="btn btn-primary" data-dismiss="modal" type="button">
                    <i className="fas fa-times mr-1"></i>
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js"></script>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>

    <script src="assets/mail/jqBootstrapValidation.js"></script>
    <script src="assets/mail/contact_me.js"></script>

    <script src="js/scripts.js"></script>
  </body>
</html>

      </React.Fragment>
    );
  }
}

export default Home;
