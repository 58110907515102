import React from "react";
import moment from "moment";

export function COLUMNS_ORDERS_ACCOUNT_MOBILE(toggleEstatus) {
  return [
    {
      dataField: "ord_handler",
      text: "TRACKINGS",
      headerAlign: 'center',
      classes: 'row-no-padding-tb',
      searchable: true,
      formatter: toggleEstatus,
    }
  ];
}

export function COLUMNS_ORDERS_ACCOUNT(toggleEstatus) {
  return [
    {
      dataField: "ord_track",
      text: "TRACKING",
      headerAlign: 'center',
      classes: 'text-left',
    },
    {
      dataField: "ord_link",
      text: "LINK",
      headerAlign: 'center',
      classes: 'text-left',
      formatter: (cell, row) => <a className="label-link" rel="noopener noreferrer" target="_blank" href={row.ord_link}>{row.ord_desc}</a>,
      
    },
    {
      dataField: "ord_date_order",
      text: "ORDENADO",
      headerAlign: 'center',
      classes: 'text-center',
      formatter: (cell, row) => moment(row.ord_date_order).format("YYYY-MM-DD"), 
    },
    {
      dataField: "suc_desc",
      text: "UBICACION",
      headerAlign: 'center',
      classes: 'text-center',
    },
    {
      dataField: "ord_handler",
      text: "ESTATUS",
      headerAlign: 'center',
      formatter: toggleEstatus,
    }
  ];
}

export function COLUMNS_TRACKS_ACCOUNT(toggleEstatus) {
    return [
      {
        dataField: "tr_track",
        text: "TRACKING",
        headerAlign: 'center',
        classes: 'text-left',
      },
      {
        dataField: "tr_libra",
        text: "PESO",
        headerAlign: 'center'
      },
      {
        dataField: "tr_price",
        text: "PRECIO",
        headerAlign: 'center',
        formatter: (cell, row) => `$ ${parseFloat(row.tr_price).toFixed(2)}`,
      },
      {
        dataField: "suc_desc",
        text: "UBICACION",
        headerAlign: 'center'
      },
      {
        dataField: "tr_handler",
        text: "ESTATUS",
        headerAlign: 'center',
        formatter: toggleEstatus,
      }
    ];
  }

  export function COLUMNS_TRACKS_ACCOUNT_FILTER(toggleEstatus,toggleLibra,togglePrice,toggleQty) {
    return [
      {
        dataField: "tr_track",
        text: "TRACKING",
        headerAlign: 'center',
        classes: 'text-left',
        footerClasses: 'text-left',
        footer: toggleQty
        // columnData => "CANTIDAD DE PAQUETES   :   " + columnData.length
      },
      {
        dataField: "tr_libra",
        text: "PESO",
        headerAlign: 'center',
        footerClasses: 'text-center',
        footer: toggleLibra
        // columnData =>  columnData.reduce((acc, item) => (Number(acc) + Number(item))  , 0) 
      },
      // <Label className="label-total-libra">TOTAL DE LIBRAS   :</Label>+
      {
        dataField: "tr_price",
        text: "PRECIO",
        headerAlign: 'center',
        formatter: (cell, row) => `$ ${parseFloat(row.tr_price).toFixed(2)}`,
        footer: togglePrice
        // columnData => "$ " + columnData.reduce((acc, item) => (Number(acc) + Number(item))  , 0) 
      },
      {
        dataField: "suc_desc",
        text: "UBICACION",
        headerAlign: 'center',
        footer:""
      },
      {
        dataField: "tr_handler",
        text: "ESTATUS",
        headerAlign: 'center',
        formatter: toggleEstatus,
        footer: ''
      }
    ];
  }

  export function COLUMNS_TRACKS_ACCOUNT_MOBILE(toggleEstatus) {
    return [
      {
        dataField: "tr_handler",
        text: "TRACKINGS",
        headerAlign: 'center',
        classes: 'row-no-padding-tb',
        formatter: toggleEstatus,
      }
    ];
  }

  export function COLUMNS_TRACKS_ACCOUNT_FILTER_MOBILE(toggleEstatus,toggleEstatus2) {
    return [
      {
        dataField: "tr_handler",
        text: "TRACKINGS",
        headerAlign: 'center',
        classes: 'row-no-padding-tb',
        formatter: toggleEstatus,
        footerClasses: 'alert-success-div',
        footer: toggleEstatus2
      }
    ];
  }
  