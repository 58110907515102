import React from "react";
import ReactDOM from "react-dom";
import {
  Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import "./assets/css/main.css";
import "./assets/css/color-switcher.css";
import "./assets/css/responsive.css";
import StoreProvider from "./Store/Provider.js";
import History from "./components/constant/History";
import { RoutesPrivate } from "./components/Routes/Private";
import Navigation from "../src/components/Menu/Navigation.js";
import Home from "../src/components/Home/Index.js";
import Sales from "../src/components/Sales/Index.js";
import Software from "../src/components/Software/Index.js";
import Store from "../src/components/Store/Index";
import Contact from "../src/components/Contact/Index.js";
import Profile from "../src/components/Profile/Index.js";
import LayoutAccount from "./components/Menu/Index";
import HomeAPBOX from "./components/Home/IndexAp";
import HomeYermain from "./components/Home/yermain";
import NavigationAPBox from "../src/components/Menu/NavigationAPBox";
import NavigationYermain from "../src/components/Menu/NavigationYermain";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/bootstrap.min.css";
import "./assets/css/colors/green.css";
import "./assets/css/main.css";
import {LOCAL_NAME} from "./components/constant/Services";
const App = () => (
 <Router  history={History} basename="/">
       <StoreProvider>
        <Switch>
        <Route exact path="/" render={() => <Redirect to="/home/info" />} />
          <Route path="/home/:path?" exact>
            <Navigation />
          <Route
            exact
            path="/home"
            render={() => <Redirect to="/home/info" />}
          />
          <Route path="/home/info" component={Home}></Route>
          <Route path="/home/sales" component={Sales}></Route>
          <Route path="/home/software" component={Software}></Route>
          <Route path="/home/store" component={Store}></Route>
          <Route path="/home/contact" component={Contact}></Route>
       </Route>
          <Route path="/profile/:path?" exact>
            <LayoutAccount>
              <RoutesPrivate
                exact
                path="/profile/account"
                component={Profile}
              ></RoutesPrivate>
            </LayoutAccount>
          </Route>
        </Switch>
      </StoreProvider>
 </Router>

);

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
