import React from "react";
import UploadImage from "./FileUpload";
import { Modal, ModalBody, Row, Col, ModalHeader,Input,FormGroup } from "reactstrap";
import { urlFiles } from "./../constant/Services";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import Input from "reactstrap/lib/Input";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.mtechnologypanama.com/">
        M-TECHNOLOGY
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const OkModal = ({ isOpen, toggle, usr_name }) => {
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-xs">
        <ModalHeader toggle={toggle}>Registro completo!</ModalHeader>
        <ModalBody>
          <Row className="text-center">
            <Col xs={2}></Col>
            <Col xs={8}>
              <h3>Dear, {usr_name}</h3>
              <p className="thanks">
                Gracias por crear su cuenta con nosotros, su número de teléfono
                es su cuenta de usuario. Ahora inicie sesión con su número de
                teléfono y contraseña..
              </p>
            </Col>
            <Col xs={2}></Col>
            <Col xs={12} className="mt-2 text-center">
              <button type="button" class="btn btn-success" onClick={toggle}>
                OK
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const ModalExampleA = ({ isOpen, toggle, dataF, toggleCopy }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-xs modal-no-mt">
        <ModalHeader toggle={toggle}>Tu Dirección P.O Box Aérea</ModalHeader>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
            <FormGroup>
              <Input
                type="textarea"
                rows={8}
                name="usr_box_pr"
                id="usr_box_pr"
                placeholder="Box pr"
                // onChange={onchangeFormUser}
                value={
                  dataF.usr_box_pr 
                }
                disabled
              />
            </FormGroup>
              {/* <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="Country"
                label="Country"
                name="Country"
                defaultValue={"United States"}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="Name"
                label="Name"
                name="Name"
                defaultValue={dataF.usr_code + " " + dataF.usr_name}
                autoFocus
              />
              <TextField
                className="w-100"
                variant="outlined"
                margin="dense"
                required
                id="Streetaddress"
                name="Streetaddress"
                label="Street address"
                defaultValue={"5401 NW 72ND AVE"}
              />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="Streetaddress2"
                label="Street address 2"
                name="Streetaddress2"
                defaultValue={"Unit 2"}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                defaultValue={"Doral"}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="state"
                label="State"
                name="state"
                defaultValue={"Florida"}
                autoFocus
              />

              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="zipcode"
                label="Zip code"
                name="zipcode"
                defaultValue={"33166-4941"}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="phonenumber"
                label="Phone number"
                name="phonenumber"
                defaultValue={"+305 364-5238"}
                autoFocus
              /> */}
            </form>

            <form className={classes.form} noValidate>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="mt-2"
                onClick={toggleCopy}
              >
                Copiar
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="secondary"
                className="mt-2 mb-3"
                onClick={toggle}
              >
                Cerrar
              </Button>
            </form>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};

const ChangeAvatar = ({ isOpen, toggle, IMG_AVATAR, nameImg, urlHttp }) => {
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-xs">
        <ModalHeader toggle={toggle}>Cambiar Avatar</ModalHeader>
        <ModalBody>
          <UploadImage
            nameImg={nameImg}
            IMG_AVATAR={IMG_AVATAR}
            urlHttp={urlHttp}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const LOGIN = ({
  onChange,
  isOpen,
  toggle,
  toggleSave,
  toggleSaveRegister,
  dataFTeacher,
  msg_error,
  isRegisterOpen,
  handlerRegister,
  seTypeSex,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-xs modal-content">
        <ModalBody>
          {isRegisterOpen ? (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Registro de nuevo usuario
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_name"
                    label="Nombre completo"
                    name="usr_name"
                    onChange={onChange}
                    defaultValue={dataFTeacher.usr_name}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_email"
                    label="Email"
                    name="usr_email"
                    onChange={onChange}
                    defaultValue={dataFTeacher.usr_email}
                    autoFocus
                  />
                  <TextField
                    className="w-100"
                    variant="outlined"
                    margin="dense"
                    required
                    id="usr_birthday"
                    name="usr_birthday"
                    label="Fecha de nacimiento"
                    type="date"
                    defaultValue={dataFTeacher.usr_birthday}
                    onChange={onChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ButtonGroup
        
                    variant="contained"
                    size="large"
                    className="w-100"
                  >
                    <Button
                      color="primary"
                      onClick={() => seTypeSex({ usr_sex: "1" })}
                      className="w-50"
                    >
                      Hombre
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => seTypeSex({ usr_sex: "2" })}
                      className="w-50"
                    >
                      Mujer
                    </Button>
                  </ButtonGroup>

                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_celphone"
                    label="Celular"
                    name="usr_celphone"
                    defaultValue={dataFTeacher.usr_celphone}
                    onChange={onChange}
                    autoFocus
                  />

                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_password1"
                    label="Contraseña"
                    name="usr_password1"
                    defaultValue={dataFTeacher.usr_password1}
                    onChange={onChange}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_password2"
                    label="Repetir contraseña"
                    name="usr_password2"
                    defaultValue={dataFTeacher.usr_password2}
                    onChange={onChange}
                    autoFocus
                  />

                  <Button
                    className="mt-2"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={toggleSaveRegister}
                  >
                    Registrarme
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={toggle}
                    className="mt-2"
                  >
                    Cancelar
                  </Button>
                </form>
              </div>
              <div className="w-100 text-right">
                {msg_error.length > 0 ? (
                  <label className="l-color-danger m-auto">{msg_error}</label>
                ) : null}
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Container>
          ) : (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Iniciar sesión
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="celphone"
                    label="Celular"
                    name="celphone"
                    autoComplete="Celular"
                    onChange={onChange}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    onChange={onChange}
                    autoComplete="current-password"
                  />
                  {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                  <Button
                    // type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    // className={classes.submit}
                    onClick={toggleSave}
                  >
                    Iniciar sesión
                  </Button>

                  <Button
                    // type="submit"
                    // fullWidth
                    // variant="contained"
                    color="primary"
                    className="m-auto"
                    onClick={handlerRegister}
                  >
                    Registrarme
                  </Button>
                </form>
              </div>
              <div className="w-100 text-right">
                {msg_error.length > 0 ? (
                  <label className="l-color-danger m-auto">{msg_error}</label>
                ) : null}
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Container>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const PROFILE = ({
  onChange,
  isOpen,
  toggle,
  toggleSave,
  dataFormUser,
  msg_error,
  defaultAvatar,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-xs modal-content">
        <ModalBody className="no-modal-body">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className="card hovercard">
              <div className="card-background">
                <img
                  className="card-bkimg"
                  alt=""
                  src={urlFiles + "/avatars/" + defaultAvatar}
                />
              </div>
              <div className="useravatar">
                <img alt="" src={urlFiles + "/avatars/" + defaultAvatar} />
              </div>
              <div className="card-info">
                <span className="card-title">
                  {dataFormUser.usr_name.toUpperCase()} -{" "}
                  {dataFormUser.usr_code.toUpperCase()}
                </span>
              </div>
            </div>

            <div>
              <Typography
                component="h1"
                variant="h5"
                className="text-center mt-1"
              >
                Perfil
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="usr_name"
                  label="Nombre"
                  name="usr_name"
                  value={dataFormUser.usr_name}
                  onChange={onChange}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  type="date"
                  margin="dense"
                  required
                  fullWidth
                  id="usr_birthday"
                  label="Fecha de nacimiento"
                  name="usr_birthday"
                  value={dataFormUser.usr_birthday}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="usr_email"
                  label="E-mail"
                  id="usr_email"
                  value={dataFormUser.usr_email}
                  onChange={onChange}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="usr_address"
                  label="Dirección"
                  id="usr_address"
                  value={dataFormUser.usr_address}
                  onChange={onChange}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="usr_password"
                  label="Contraseña"
                  id="usr_password"
                  value={dataFormUser.usr_password}
                  onChange={onChange}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="usr_password2"
                  label="Repetir contraseña"
                  id="usr_password2"
                  value={dataFormUser.usr_password2}
                  onChange={onChange}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="usr_code"
                  label="Codigo de usuario"
                  id="usr_code"
                  value={dataFormUser.usr_code}
                  onChange={onChange}
                  disabled
                />
                <Row>
                  <Col xs={6}>
                    <Button
                      // type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={toggleSave}
                    >
                      Guardar
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      // type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      onClick={toggle}
                    >
                      Salir
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
            <Box>
              <Copyright />
            </Box>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export { OkModal, LOGIN, PROFILE, ChangeAvatar, ModalExampleA };
