import React, { Component } from "react";
import Footer from "./../Menu/Footer";
import {ImageViewerAnnouncement} from "./../constant/Util";
// import {Button,Row, Col } from "reactstrap";
import { SISTEMA_FIC_ICON,STOCK_INVENTORY_ICON,POS_MOVIL_ICON,COMPRAS_INTERNET_APP,PRESTASHOP } from "../constant/Index.js";
class Software extends Component {
  constructor() {
    super();
    this.state = {
  }
}

  render() {

    return (
      <React.Fragment>
    <div className="page-header-section">
      <div className="container">
        <div className="row">
          <div className="page-header-area">
            <div className="page-header-content">
              <h2>Software</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

  
    <section id="service-block-main" className="section">

      <div className="container">
        <div className="row">        
          <div className="col-sm-6 col-md-3">
            <div className="service-item wow fadeInUpQuick animated" data-wow-delay=".5s">
              <div className="icon-wrapper">
                <ImageViewerAnnouncement ad_img="stock" src_={STOCK_INVENTORY_ICON}/>
              </div>
              <h2>
                STOCK INVENTORY
              </h2>
              <p className="text-left">
                Este software permite a los usuarios administrar el inventario de sus activos. Se trata de un programa informático que se emplea para registrar las cantidades de mercancías en existencia, desde tu smartphone. Está diseñado para ser lo más intuitivo posible, por lo que después de una instalación rápida, podrá utilizar sus procesos de inventario en solo minutos.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="service-item wow fadeInUpQuick animated" data-wow-delay=".8s">
              <div className="icon-wrapper">
              <ImageViewerAnnouncement ad_img="shop" src_={SISTEMA_FIC_ICON}/>
              </div>
              <h2>
                SISTEMA FIC
              </h2>
              <p className="text-justify">
                Es un software es en realidad una solución integral que nos ofrece una gran cantidad de opciones y funciones avanzadas para controlar el rendimiento de nuestra empresa; así como todos sus movimientos tanto de compras como de ventas.
                Tendremos todo en un mismo lugar y solucionaremos diversos problemas con tan solo un programa.
              </p>
            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <div className="service-item wow fadeInUpQuick animated" data-wow-delay="1.1s">
              <div className="icon-wrapper">
                <ImageViewerAnnouncement ad_img="pos" src_={POS_MOVIL_ICON}/>
              </div>
              <h2>
               <i>POS MOVIL</i>
              </h2>
              <p className="text-justify">
                Software de facturación móvil, inventario; formas de pago y pedidos.
              </p>
            </div>

          </div>
          <div className="col-sm-6 col-md-3">
            <div className="service-item wow fadeInUpQuick animated" data-wow-delay="1.1s">
              <div className="icon-wrapper">
              <ImageViewerAnnouncement ad_img="shop" src_={PRESTASHOP}/>
              </div>
              <h2>
               VENTAS EN LÍNEA
              </h2>
              <p className="text-justify">
                Software de facturación de ventas en línea, carrito de compras, pago en línea e inventario.
              </p>
            </div>

          </div>
          <div className="col-sm-6 col-md-3">
            <div className="service-item wow fadeInUpQuick animated" data-wow-delay="1.1s">
              <div className="icon-wrapper">
                <ImageViewerAnnouncement ad_img="track" src_={COMPRAS_INTERNET_APP}/>
              </div>
              <h2>
               COMPRAS POR INTERNET
              </h2>
              <p className="text-left">
                Sistema de administración de paquetes, inventario,clientes, pedidos, paquetes perdidos, chat y vista del lado del cliente.
              </p>
            </div>

          </div>
          </div>
        </div>

    </section>
<Footer/>
      </React.Fragment>
    );
  }
}

export default Software;
