import React from "react";
import moment from "moment";
import History from "./../constant/History";
import { LOGIN, OkModal } from "./../constant/Modal";
import StoreContext from "./../../Store/Context";
import {
  Nav,
  NavItem,
  NavLink,
  Col,
  Row
} from "reactstrap";
import { url, postRegisterUser, validateUser } from "./../constant/Services";
import "./../../assets/css/main.css";
import "./../../assets/css/main.css";

class LayoutAccount extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      children: props.children,
      loginOpen: false,
      isCollapse: false,
      okModal: false,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      isRegisterOpen:false,
      msg_error: "",
    };
    this.handleModalLogin = this.handleModalLogin.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.login = this.login.bind(this);
    this.onChangeFLogin = this.onChangeFLogin.bind(this);
    this.setError = this.setError.bind(this);
    this.handlerRegister = this.handlerRegister.bind(this);
    this.toggleSaveRegister = this.toggleSaveRegister.bind(this);
    this.seTypeSex = this.seTypeSex.bind(this);
    this.fetchValidateUser = this.fetchValidateUser.bind(this);
    this.handlerOkModal = this.handlerOkModal.bind(this)
  }
  
  handlerOkModal() {
    this.setState((prevState) => ({
      okModal: !prevState.okModal,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    }));
  }

  handleModalLogin() {
    this.setState((prevState) => ({
      loginOpen: !prevState.loginOpen,
      isRegisterOpen: false,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: moment(new Date()).format("YYYY-MM-DD"),
        usr_sex: "1",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
      isCollapse: false,
    }));
  }
  setError(error) {
    this.setState({
      msg_error: error,
    });
  }
  componentDidMount() {
    this.context.quitToken();
  }

  async login({ user, password }) {
    if (user.length > 0 && password.length > 0) {
      const dataBody = {
        user,
        password,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(dataBody),
      };
      const response = await fetch(
        url + "LoginController/login",
        requestOptions
      );

      const result = await response.json();

      if (result[0].count !== "0") {
        return {
          token: true,
          usr_id: result[0].usr_id,
          usr_name: result[0].usr_name,
          usr_type: result[0].usr_type,
        };
      } else {
        return { error: "Usuario o contraseña incorrectos" };
      }
    } else {
      return { error: "Campos estan vacios" };
    }
  }

  async onSubmit(event) {
    event.preventDefault();
    const props = this.state.dataFLogin;
    const { token, usr_id, usr_name, usr_type, error } = await this.login({
      user: props.celphone,
      password: props.password,
    });

    if (token) {
      this.context.quitToken();
      this.context.setToken({ token, usr_id, usr_name, usr_type });
      if (usr_type === "2") {
        return History.push("/admin");
      } else {
        this.setState({ isRegisterOpen: false, loginOpen: false });
        return History.push("/profile/account");
      }
    }
    this.setError(error);
  }

  toggleCollapse() {
    this.context.quitToken();
    this.setState((prevState) => ({
      isCollapse: !prevState.isCollapse,
    }));
  }
  toggleNavbar() {}

  onChangeFLogin(e) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }

  async fetchValidateUser(usr, pass) {
    const props = this.state.dataFLogin;
    const response = await validateUser(usr, pass);
    if (response.exist) {
      this.setState({
        msg_error: "Tenemos una cuenta registrada con ese numero de teléfono",
      });
    } else {
      var usr_code_ =
        response.set_siglas[0].set_siglas +
        this.paddy(response.n_box[0].n_box, 5);
      const body = {
        usr_code: usr_code_,
        usr_box_pr:
          usr_code_ +
          " " +
          props.usr_name.toUpperCase() +"\n" +response.set_address[0].set_address,
        usr_price: response.set_price_libra[0].set_price_libra,
        usr_name: props.usr_name.toUpperCase(),
        usr_user: props.usr_celphone,
        usr_celphone: props.usr_celphone,
        usr_password: props.usr_password1,
        usr_email: props.usr_email,
        usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
        usr_sex: props.usr_sex,
      };

      postRegisterUser(body);
      this.setState({
        isRegisterOpen: false,
        loginOpen: false,
        okModal: true,
      });
    }
  }

  paddy(num, padlen, padchar) {
    var pad_char = typeof padchar !== "undefined" ? padchar : "0";
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  toggleSaveRegister() {
    const props = this.state.dataFLogin;
    if (
      props.usr_name.length > 3 &&
      props.usr_email.length > 0 &&
      props.usr_birthday.length > 0 &&
      props.usr_celphone.length > 0 &&
      props.usr_password1.length > 0 &&
      props.usr_password2.length > 0
    ) {
      if (props.usr_password1 === props.usr_password2) {
        this.fetchValidateUser(props.usr_celphone, props.usr_password1);
      } else {
        this.setState({
          msg_error: "Contraseñas diferentes",
        });
      }
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  handlerRegister() {
    this.setState({
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name: "",
        usr_email: "",
        usr_birthday: "",
        usr_sex: "",
        usr_celphone: "",
        usr_password1: "",
        usr_password2: "",
      },
      msg_error: "",
    });
  }

  seTypeSex({ usr_sex }) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        usr_sex,
      },
    });
  }

  render() {
    const props = this.state;

    return (
      <React.Fragment>
        {props.okModal && (
          <OkModal isOpen={props.okModal} toggle={this.handlerOkModal} usr_name={props.dataFLogin.usr_name} />
        )}
        {props.loginOpen && (
          <LOGIN
            onChange={this.onChangeFLogin}
            isOpen={props.loginOpen}
            toggle={this.handleModalLogin}
            toggleSave={this.onSubmit}
            toggleSaveRegister={this.toggleSaveRegister}
            dataFTeacher={props.dataFLogin}
            msg_error={props.msg_error}
            isRegisterOpen={props.isRegisterOpen}
            handlerRegister={this.handlerRegister}
            seTypeSex={this.seTypeSex}
          />
        )}

        <section id="header">
          <nav
            className="navbar navbar-light"
            data-spy="affix"
            data-offset-top="50"
          >
            <div className="container px-unset">
              <div className="d-block d-sm-none w-100">
        
   
                <Nav navbar>
                      <NavItem>
                        <NavLink onClick={this.handleModalLogin} className="btn-login-movil">
                          Iniciar sesión
                        </NavLink>
                      </NavItem>
                    </Nav>
         
              </div>
            </div>
            <Col sm={10}>{this.state.children}</Col>
            <div className="d-none d-sm-block w-100">
              <Row>
                <Col sm={4}>

                </Col>
                <Col sm={8}>
                  <div
                    className="navbar-toggleable-sm pull-xs-left pull-md-right"
                    id="main-menu"
                  >
                    <Nav>

                      <NavItem>
                        <NavLink onClick={this.handleModalLogin} className="btn_login">
                          Iniciar sesión
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col sm={10}>{this.state.children}</Col>
              </Row>
            </div>
          </nav>
        </section>
      </React.Fragment>
    );
  }
}

export default LayoutAccount;