// export const url = "http://localhost/trackingApi/";
// export const url = "https://www.apservices507.com/db/index.php/";
export const url = "https://www.mtechnologypanama.com/db/index.php/";
// export const url = "https://www.internetpayrineshelle.com/db/index.php/";
export const urlFiles = "https://www.mtechnologypanama.com/db/uploads";
// export const urlFiles = "http://localhost/trackingApi/uploads";
// export const urlMultimedia = "http://localhost/trackingApi/uploads/multimedia/software/";
export const urlMultimedia = "https://www.mtechnologypanama.com/db/uploads/multimedia/software/";
// export const LOCAL_NAME="APBOX";
export const LOCAL_NAME="MTECHNOLOGY";
export const USR_SUC="1";
export const nonce="enc-base64";
export const path="login";
export const privateKey="MIICXAIBAAKBgQCqGKukO1De7zhZj6+H0qtjTkVxwTCpvKe4eCZ0FPqri0cb2JZfXJ";
export const urlHttpPostAvatar =url+"UserController/uploadImg";

export const service_id = "service_50yo7zc";
export const template_id =  "template_skhzgkm";
export const user_id =  "user_rGszdMFLYPto4niOf92z3";
export const usr_name =  "COURIERMG";
export const template_idNot= "template_0zqklc6";

export async function sendEmail(data) {
  const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then(function() {
    // console.log('Your mail is sent!');
}).then(function(error) {
  // console.log('Oops... ' + JSON.stringify(error));
});
  return response;
}


export async function getUserAccount(usr_id) {
  const response = fetch(url + "UserController/getUserAccount/" + usr_id)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getTrackingAccount(usr_id) {
    const response = fetch(url + "TrackingController/getTrackingAccount/" + usr_id)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function getSettingAccount(usr_id) {
    const response = fetch(url + "UserController/getSettingAccount/" + usr_id)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function getOrderAccount(usr_id) {
    const response = fetch(url + "OrderController/getOrderAccount/" + usr_id)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export async function putProfile(data) {
    const response = await fetch(url + "UserController/putProfile", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
    
  export async function postRegisterUser(data) {
    const response = await fetch(url + "UserController/postRegisterUser", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function validateUser(usr,pass) {
    const response = fetch(url + "LoginController/validateUser/" + usr+"/"+pass)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export async function getUserDataAdmin(usr_id) {
    const response = fetch(url + "LoginController/getUserDataAdmin/" + usr_id)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }